import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useGetHostTransactionMutation,useTransactionHostCountMutation } from '../../features/transaction/transactionSlice'
import { useWithdrawalHostMutation } from '../../features/transaction/transactionSlice'
import "../../asserts/css/theme.min.css"
import Skeleton from 'react-loading-skeleton'


function PayOutHistory() {
    const [getpayoutlist,{isLoading}]=useGetHostTransactionMutation();
    const [getPaymentDetails,{isLoading:isLoadingPayment}]=useTransactionHostCountMutation(); 
    const [withdraw,{isLoading:isWithdrawing}]=useWithdrawalHostMutation()
    const [transaction,setTransaction]=useState()
    const [nextTransactionList,setNextEvent]=useState()
const [previousTransactionList,setPreviousEvent]=useState()
const [paymentDetails,setPaymentDetails]=useState()
const [page,setPage]=useState(1)
const [pageCount,setPageCount]=useState()

    const handlegettransaction=async(page)=>{
        try{

          const params={
            page:page,
          }
           const response = await getpayoutlist(params).unwrap()
           setTransaction(response.results);

           console.log(response)
           setNextEvent(response.next)
setPreviousEvent(response.previous)
setPageCount(0)
setPageCount(Math.ceil(response.count / 10))
console.log("page count: " ,pageCount)

        }catch(error){
            console.log(error)
        }
    }

    const handlePagination=(page)=>{
      setPage(page);
      console.log(page)
      handlegettransaction(page);
    }


    const handlegetpaymentdetails= async()=>{
      try{

        const response= await getPaymentDetails().unwrap()
        console.log(response) 
        setPaymentDetails(response)

      }
      catch(e){
        console.log(e);
      }

    }
    


    useEffect(()=>{
        handlegettransaction()
        handlegetpaymentdetails()
    },[])

    function formatDate(isoString) {
      const date = new Date(isoString);
      const options = {
        year: 'numeric',
        month: 'short',  // Use 'short' for abbreviated month names (e.g., Jan, Feb, Dec)
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,  // 12-hour format
      };
      return date.toLocaleString('en-US', options);
    }
    



  return (


  <div class="col-lg-8 col-md-7 mb-5 ">


  <div class="row mb-4">
  <div class='col-lg-4 col-md-6'>
  
<div class="card text-center shadow-md shadow">
  <div class="card-body">
    <h6 class="card-title ">Pending Funds</h6>

  </div>
  <div class="card-footer text-warning fs-sm  h6">
  
  {     isLoadingPayment?<Skeleton style={{height:"25px"}}/>:paymentDetails&&paymentDetails.pending_amount}</div>
</div>

</div>

<div class='col-lg-4 col-md-6'>
  
  <div class="card text-center  mt-3 mt-md-0  shadow-md shadow">
	<div class="card-body">
	  <h6 class="card-title ">Available Funds</h6>
  
	</div>
	<div class="card-footer fs-sm  text-success h6">
  <>
  {
    isLoadingPayment?<Skeleton style={{height:"25px"}}/>:
    paymentDetails && paymentDetails.withdrawal_amount}
  </>

  </div>
  </div>
  
  </div>

  <div class='col-lg-4 col-md-6 '>
  
  <button type="button" class="btn btn-primary mt-3  w-100 mt-md-3 mt-lg-5 ">
  <i class="fi-accounting me-2"></i>
  Withdraw Funds
</button>
  
  </div>

  </div>

				<div class="card card card-body border-0 shadow-sm pb-1 me-lg-1">
<div className='w-100 d-flex justify-content-end '>
<div>
<select class="form-select mx-auto" id="sortby">
<option value="date-desc">Credit</option>
<option value="date-asc" selected>Pending</option>
<option value="featured-desc">Success</option>
<option value="featured-desc">System</option>

</select>
</div>
</div>
				
					<div class="pt-2">
						<label class="form-label fw-bold mb-3">Recent Transaction</label>
					</div>

          <div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Description</th>
        <th scope="col">Status</th>
        <th scope="col">Date</th>
        <th scope="col">Mode</th>
        <th scope="col">Amount</th>      
        <th scope="col">Action</th>
      </tr>
    </thead>
    <tbody>
      {
        transaction && 
        transaction.map((item, index) => (
          <tr key={index}>
            <td class="text-nowrap">{item.id}</td>
            <td class="text-nowrap">{item.description}</td>
            <td class="text-nowrap">
              <span className={`${item.status === "success" ? "text-success" : "text-danger"}`}>
                {item.status}
              </span>
            </td>
            <td class="text-nowrap">
              {formatDate(item.date_created)}
            </td>
            <td class="text-nowrap">{item.mode}</td>
            <td class="text-nowrap"><span className='fw-bold'>GH₵ {item.amount}</span></td>
            <td class="text-nowrap">
              <span type="button" style={{ cursor: "pointer" }} class="text-decoration-underline text-primary">
                 print receipt
              </span>
            </td>
          </tr>
        )) 
        || isLoading &&
        <>    
          <tr><td colSpan="7" class="text-center"><Skeleton/></td></tr> 
          <tr><td colSpan="7" class="text-center"><Skeleton/></td></tr>
          <tr><td colSpan="7" class="text-center"><Skeleton/></td></tr>
          <tr><td colSpan="7" class="text-center"><Skeleton/></td></tr>
        </>
      }
      {
        transaction && transaction.length < 1 ?
        <tr>
          <td colspan="6" class="text-center">No transaction at the moment!</td>
        </tr>
        : ""
      }
    </tbody>
  </table>
  <div className='w-100 d-flex justify-content-end'>

  
  {
  pageCount<=1?
  ""
  :
  <nav className="mt-2 mb-4" aria-label="Reviews pagination">
              <ul className="pagination">
                <li className="page-item d-sm-none"><span className="page-link page-link-static">1 / 5</span></li>
                {
                  previousTransactionList?
                  <li className="page-item"><span onClick={()=>{handlePagination(page -1)}} className="page-link" href="#" aria-label="Previous"><i className="fi-chevron-left"></i></span></li>
                  :
                  ""


                }
                {
                  page-2>0 && page-2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page - 2)}}>{page-2}</span></li>
                  :""


                }
                {
                 page>0 &&previousTransactionList?

                  <li className="page-item d-none d-sm-block"><span onClick={()=>{handlePagination(page-1)}} className="page-link" href="#">{page-1}</span></li>
                  :
                  ""


                }
                
                {
                  page>0?
                  <li className="page-item active d-none d-sm-block" aria-current="page"><span className="page-link">{page}<span className="visually-hidden">(current)</span></span></li>
                  :''


                }

                {
                  nextTransactionList?
                  <li className="page-item d-none d-sm-block"><span className="page-link" onClick={()=>{handlePagination(page+ 1)}}>{page+1}</span></li>

                  :""

                }
                {
                  page+2<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link" href="#" onClick={()=>{handlePagination(page + 2)}}>{page+2}</span></li>
                  :""


                }

                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block">...</li>
                  
                  :""
                }
                {
                  page+4<=pageCount?
                  <li className="page-item d-none d-sm-block"><span className="page-link"  onClick={()=>{handlePagination(pageCount&&pageCount)}} href="#"> {pageCount&&pageCount}</span></li>
                  
                  :"" 
                }
                {
                  nextTransactionList?
                  <li className="page-item"><span  onClick={()=>{handlePagination(page + 1)}} className="page-link" href="#" aria-label="Next"><i className="fi-chevron-right"></i></span></li>
                  :
                  ""

                }
                
                
              </ul>
            </nav>
}

  </div>
</div>


				</div>
				

			</div>
  
  )
}

export default PayOutHistory