import React, { useState ,useEffect,useRef} from 'react'
import { jarallax } from 'jarallax';
import 'jarallax/dist/jarallax.css';
import image from '../../asserts/img/real-estate/hero-image-v2.jpg';
import house from "../../asserts/img/layout/House searching (3).gif"
import { useGetCategoriesMutation } from '../../features/property/propertySlice'
import { useNavigate } from 'react-router-dom'
import Autocomplete from "react-google-autocomplete";

function Layout2({layoutdata}) {
    useEffect(() => {
        jarallax(document.querySelectorAll('.jarallax'), {
          speed: 0.7
        });
      }, []);


      

  const [data,setData]=useState({
   
    "category":"",
    "price_max":"",
    "price_min":""
})
const navigate=useNavigate()


const handleChange=(event) =>{
  const {name,value}=event.target
  setData({...data,[name]:value})
  
}

const [location,setLocation]=useState('')




  const [getcategories,{isLoading}]=useGetCategoriesMutation()


  const [category,setcategory]=useState(false)
  const [categories,setCategories]=useState(false)

  const categoryref= useRef(null);
 
  const handleGetCategories=async()=>{
    try{
  
      const categories= await getcategories()
      console.log(categories)
      setCategories(null)
      setCategories(categories.data)
    
  
    }catch(e){
      console.log(e)
  
    }
  }
  
  useEffect(()=>{
    handleGetCategories()
  },[])


  const handleClickOutside = (event) => {
    if (categoryref.current && !categoryref.current.contains(event.target)) {
      setcategory(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const locationref=useRef(null)

  const handleChangeCity=()=>{

    setLocation(locationref.current.value.replace("Ghana", '',).replace(",",""));
    
  }


  const handleSubmit = (e) => {
    e.preventDefault(); 
    localStorage.setItem('city',location);
    localStorage.setItem('category_name',data.category);
    localStorage.setItem('pricemax',data.price_max);
    localStorage.setItem('pricemin',data.price_min);
    console.log(data);

    setData({
      "location": "",
      "category": "",
      "price_max": "",
      "price_min": ""
    });
    navigate('/properties')
  }    
  return (
    <>
    <section className="container-fluid my-5 pt-5 pb-lg-4 px-xxl-4">
    <div 
      className="jarallax card align-items-center justify-content-center border-0 p-md-5 p-4 bg-secondary overflow-hidden mt-n3"
      style={{ minHeight: '80vh', backgroundImage: `url(${layoutdata&&layoutdata.image1}})`,backgroundSize: 'cover' } }
      data-jarallax data-speed="0.5"
    >
      <span className="img-overlay opacity-40"></span>
      <div className="content-overlay " style={{ maxWidth: '856px' }}>

      <h1 className="display-5 mb-4 me-lg-n5  text-center mb-4 text-white">{layoutdata&&layoutdata.title}</h1>
        <form className="form-group d-block">
          <div className="row g-0">
            <div className="col-md-10 d-sm-flex align-items-center">
              <div className="dropdown w-sm-50 border-end-sm" data-bs-toggle="select">
              <div class="d-flex align-items-center w-100 pt-2 pb-4 py-sm-0 ps-2 ps-sm-3">
					<i class="fi-cash fs-lg text-muted me-2"></i>
					
					<input 
          name="price_max" 
      value={data.price_min} 
      onChange={handleChange} 
    
           class="form-control range-slider-value-min text-muted" type="number"  placeholder='100' />
					<div class="text-muted">—</div>
					<input
          name="price_min" 
      value={data.price_max} 
      onChange={handleChange} 
     
     class="form-control range-slider-value-max text-muted" placeholder='1000' type="number"  />
				</div>
              </div>
              <hr className="d-sm-none my-2" />
              <div className="dropdown w-sm-50 border-end-sm ps-2 ps-sm-3 d-flex   align-content-center justify-content-center" >
                 <i className="fi-map-pin mt-3"></i>


<Autocomplete
  apiKey={"AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg"}  // Replace with your actual API key

  options={{
    types: ['(cities)'],   // restrict search to addresses only
    componentRestrictions: { country: "Gh" }  // limit search to United States
  }}
  value={data.location}
  onPlaceSelected={handleChangeCity}
 
 
onChange={handleChangeCity}
ref={locationref}
  
  className='form-control '
  placeholder="city"
 


/>
                    
                    
                
                  </div>

              <hr className="d-sm-none my-2" />
             
              <div className="dropdown w-sm-50 border-end-md d-flex ps-2 ps-sm-3" data-bs-toggle="select">
                  <i className="fi-list  mt-3"></i>
                  <select 
                  
                  name="category" 
      value={data.category} 
      onChange={handleChange} 
      placeholder="Location" 
                  className=" w-100 form-control"  >
  <option disabled hidden value='' selected>

    category
  </option>
  {
    categories && categories.map((category, index) => {
      return (
        <option key={index}>{category.name}</option>
      )
    })
  }
</select>

                  </div>
            </div>
            <hr className="d-md-none mt-2" />
            <div className="col-md-2 d-sm-flex align-items-center pt-3 pt-md-0">
              <button className="btn btn-lg btn-icon btn-primary px-3 w-100" type="button" >Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
 
      </>
  )
}

export default Layout2