import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <GoogleOAuthProvider clientId="161170629728-sff10vprdkpkkvlov11arct0im6kq5jo.apps.googleusercontent.com">


  <Provider store={store}>

  
 <App/>
 </Provider>
 </GoogleOAuthProvider>
  </React.StrictMode>
);


