import React ,{useState,useEffect,useRef}from 'react'

import Sidebar from '../../components/profilecomponents/sidebar'
import { Link } from 'react-router-dom'
import { useGetprofileMutation ,useUpdateprofileMutation} from '../../features/auth/authApiSlice'
import { useUpdateprofile1Mutation } from '../../features/auth/authApiSlice'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import "../../asserts/css/theme.min.css"


registerPlugin(FilePondPluginImagePreview);
function ProfilePage() {



	
	const [files, setFiles] = useState([]);
	const [inputFirstName, setInputFirstNameVisible] = useState(false);
	const [inputLastName, setInputLastNameVisible] = useState(false);
	const [inputEmail, setInputEmailVisible] = useState(false);
	const [inputPhone, setInputPhoneVisible] = useState(false);
	const [inputCompanyName, setInputCompanyNameVisible] = useState(false);
	const [inputAddress, setInputAddressVisible] = useState(false);
	const [inputNationality, setInputNationalityVisible] = useState(false);
	const [inputIDType, setInputIDTypeVisible] = useState(false);
	const [inputIDNumber, setInpuIDNumberVisible] = useState(false);
	const [inputSex, setInputSexVisible] = useState(false);
	const [inputEmergencyContact, setInputEmergencyContactVisible] = useState(false);
	const [showMore, setShowMore] = useState(false);
	const [showAlert,setShowAlert]=useState(false)
	const [showErrorAlert,setShowErrorAlert]=useState(false)
	const [errorMessage,setErrorMessage]=useState(null)
	const [file, setFile] = useState(null);
	const formref=useRef()






	const [newProfileData,setNewProfileData] = useState({
		"first_name": "",
		"last_name": "",
		"email": "",
		"gender": "",
		"phone_number": "",
		"company_name": "",
		"address": "",
		"nationality": "",
		"national_id_type": "",
		"national_id_number": "",
		"emergency_contact": "",
		"facebook_link": "",
		"linkedin_link": "",
		"twitter_link": "",
		"instagram_link": "",
		"description": "",
		"tiktok_link": "",
		"whatsapp_number": "",
		
	});

	const handleChange = (event) => {
		const { name, value } = event.target;
		setNewProfileData((newProfileData) => ({ ...newProfileData, [name]: value }));
	  };
	

	const [getprofile,{isLoading}]=useGetprofileMutation()
	const [updateprofile,{isLoading:isLoadingUpdate}]=useUpdateprofile1Mutation()
	const [userData,setUserData]=useState({
		"first_name":"",
		"last_name":"",
		"email":"",
		"phone_number":"",
		"company_name":"",
		"address":"",
		"nationality":"",
		"id_type":"",
		"id_number":"",
		"sex":"",
		"emergencycontact":"",
		"tiktok_link":"",
		"twitter_link":"",
		"whatsapp_number":"",
		"facebook_link":"",
		"linkedin_link":"",
		"instagram_link":"",
		"description":""
		
		

	})



	const [profilePercentage,setProfilePercentage]=useState(null)



	
	
	  
	  const hangleGetProfile=async()=>{
		try{
		const profiledata=await getprofile()
	
		console.log("profile_data",profiledata)
		
		setUserData({
			...userData,
			"first_name":profiledata.data.first_name,
			"last_name":profiledata.data.last_name,
			'email':profiledata.data.email,
			'phone_number':profiledata.data.phone_number,
			"company_name":profiledata.data.company_name,
			'address':profiledata.data.address,
			"nationality":profiledata.data.nationality,
			"id_type":profiledata.data.national_id_type,
			"id_number":profiledata.data.national_id_number,
			"sex":profiledata.data.gender,
			'emergencycontact':profiledata.data.emergency_contact,
			"tiktok_link":profiledata.data.tiktok_link,
		"twitter_link":profiledata.data.twitter_link,
		"whatsapp_number":profiledata.data.whatsapp_number,
		"facebook_link":profiledata.data.facebook_link,
		"linkedin_link":profiledata.data.linkedin_link,
		"instagram_link":profiledata.data.instagram_link,
		"description":profiledata.data.description,
			"profile":profiledata.data.profile





		})
		

		if (profiledata.data.profile !==null){
			
			const url=profiledata.data.profile.replace("https://api.shortstaybuddy.com","")


			fetch(url)
			.then(response => {
			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }
			  return response.blob();
			})
			.then(blob => {
			  // Create a File object from the Blob
			  const file = new File([blob], 'profile-picture.png', { type: 'image/png' });
	  
			  // Add the file object to the FilePond files array
			  setFiles([file]);
			})
			.catch(error => {
			  console.error('Error fetching image:', error);
			});
		}


		setNewProfileData({...newProfileData,
			"first_name":profiledata.data.first_name || "",
			"last_name":profiledata.data.last_name || "",
			'email':profiledata.data.email || "",
			'phone_number':profiledata.data.phone_number || "",
			"company_name":profiledata.data.company_name || "",
			"gender":profiledata.data.gender || "",
			"address": profiledata.data.address || "",
			"nationality": profiledata.data.nationality|| "",
			"national_id_type":profiledata.data.national_id_type|| "",
			"national_id_number": profiledata.data.national_id_number|| "",
			"emergency_contact": profiledata.data.emergency_contact|| "",
			"facebook_link": profiledata.data.facebook_link|| "",
			"linkedin_link": profiledata.data.linkedin_link|| "",
			"twitter_link": profiledata.data.twitter_link|| "",
			"instagram_link": profiledata.data.instagram_link|| "",
			"description": profiledata.data.description|| "",
			"tiktok_link": profiledata.data.tiktok_link|| "",
			"whatsapp_number": profiledata.data.whatsapp_number|| "",
			"profile":profiledata.data.profile
			
		});
	

		const nonNullCount = Object.values(profiledata.data).filter(value => value !== null && value !== "").length;

// Calculate the percentage
const totalProperties = Object.keys(profiledata.data).length;
const percentage = (nonNullCount / totalProperties) * 100;

setProfilePercentage(percentage.toFixed(0))
		console.log("userdata",userData)

	}catch(e){

	}
	  }

	  useEffect(()=>{
		hangleGetProfile()
	  },[])


	const   handleSubmitNewData= async (e)=>{
		e.preventDefault()
		const formData = new FormData();
		console.log(newProfileData)

		formData.append('first_name', newProfileData.first_name);
		formData.append('last_name', newProfileData.last_name);
		formData.append('email', newProfileData.email);
		formData.append('phone_number', newProfileData.phone_number);
		formData.append('company_name', newProfileData.company_name);
		formData.append('gender', newProfileData.gender);
		formData.append('address', newProfileData.address);
		formData.append('nationality', newProfileData.nationality);
		formData.append('national_id_type', newProfileData.national_id_type);
		formData.append('national_id_number', newProfileData.national_id_number);
		formData.append('emergency_contact', newProfileData.emergency_contact);
		formData.append('facebook_link', newProfileData.facebook_link);
		formData.append('linkedin_link', newProfileData.linkedin_link);
		formData.append('twitter_link', newProfileData.twitter_link);
		formData.append('instagram_link', newProfileData.instagram_link);
		formData.append('description', newProfileData.description);
		formData.append('tiktok_link', newProfileData.tiktok_link);
		formData.append('whatsapp_number', newProfileData.whatsapp_number);
		formData.append('balance', '200');

	if (files.length > 0) {
		formData.append('profile', files[0].file);
	  }
	

	
	for (let [key, value] of formData.entries()) {
		console.log(`${key}: ${value}`);
	}
		try{
			const updateData= await updateprofile(formData).unwrap()
			console.log(updateData)
			setShowAlert(true)


		}
		catch(e){
			console.log(e)
			if (e.status===400){
				
				setErrorMessage(e.data.detail)
				setShowErrorAlert(true)
			}
		}



	}





	

  return (
    
       
            <div className="col-lg-8 col-md-7 mb-5">
				<form  onSubmit={handleSubmitNewData} ref={formref} encType="multipart/form-data" >

	<h1 className="h2">Personal Info</h1>
	{
		showAlert?
		<div class="alert alert-success alert-dismissible fade show" role="alert">
  <span class="fw-bold">Success:</span> Profile Updated Successfully
  <button  onClick={()=>{setShowAlert(false)}} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:""
	}
	
	{
		showErrorAlert?
		<div class="alert alert-danger alert-dismissible fade show" role="alert">
  
  
  {errorMessage ? (
        <div>
          {Object.keys(errorMessage).map((key) => (
            <div key={key}>
              <p><span class="fw-bold">{key}  </span> : {errorMessage[key][0]}</p>
            </div>
          ))}
        </div>
      ) : (
        ""
      )

  }
  <button  onClick={()=>{setShowErrorAlert(false)}} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>:""
	}
	
	
	
		<div className="mb-2 pt-1">Your personal info is { profilePercentage?profilePercentage+"%":"0%"} completed</div>
		<div className="fs-sm mb-2">{ profilePercentage?profilePercentage+"%":"0%"}
			</div>
<div className="progress mb-3" style={{height: '4px'}}>
  <div className="progress-bar bg-warning" role="progressbar" style={{width: `${ profilePercentage?profilePercentage+"%":"0%"}` }} aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"></div>
</div>
	<label className="form-label pt-2" for="account-bio">Short bio</label>
	<div className="row pb-2">
		<div className="col-lg-9 col-sm-8 mb-4">
			<textarea name='description' value={newProfileData.description} onChange={handleChange} className="form-control" id="account-bio" rows="6" placeholder="Write your bio here. It will be displayed on your public profile."></textarea>
			
		</div>

		<div className="col-lg-3 col-sm-4 mb-4">

		{
		 userData.profile  ? 
		 <div className="file-uploader bg-secondary" style={{ height: '160px' }}>
	
	<FilePond
	  files={files}
	  onupdatefiles={setFiles}
	  allowMultiple={false}
	  name="avatar_url"
	  

	
	  
	

	  labelIdle='<i class="d-inline-block fi-camera-plus fs-2 text-muted mb-2 " Style="margin-top:60px"></i><br><span class="fw-bold">Change picture</span>'
	  stylePanelLayout="compact"
	  styleButtonRemoveItemPosition="left"
	  styleButtonProcessItemPosition="right"
	  styleLoadIndicatorPosition="right"
	  styleProgressIndicatorPosition="right"
	  styleButtonRemoveItemAlign={false}
	  acceptedFileTypes={['image/png', 'image/jpeg','image/jpg']}
	/>
	<a
	  className="filepond--credits"
	  aria-hidden="true"
	  href="https://pqina.nl/"
	  target="_blank"
	  rel="noopener noreferrer"
	  style={{ transform: 'translateY(152px)' }}
	>
	  Powered by PQINA
	</a>
  </div>:
  <div className="file-uploader bg-secondary" style={{ height: '160px' }}>
	
	<FilePond
	  files={files}
	  onupdatefiles={setFiles}
	  allowMultiple={false}
	  name="avatar_url"
	  
	
	  
	

	  labelIdle='<i class="d-inline-block fi-camera-plus fs-2 text-muted mb-2 " Style="margin-top:60px"></i><br><span class="fw-bold">Change picture</span>'
	  stylePanelLayout="compact"
	  styleButtonRemoveItemPosition="left"
	  styleButtonProcessItemPosition="right"
	  styleLoadIndicatorPosition="right"
	  styleProgressIndicatorPosition="right"
	  styleButtonRemoveItemAlign={false}
	  acceptedFileTypes={['image/png', 'image/jpeg','image/jpg']}
	/>
	<a
	  className="filepond--credits"
	  aria-hidden="true"
	  href="https://pqina.nl/"
	  target="_blank"
	  rel="noopener noreferrer"
	  style={{ transform: 'translateY(152px)' }}
	>
	  Powered by PQINA
	</a>
  </div>
		}
		
		</div>
	</div>


	<div className="border rounded-3 p-3 mb-4" id="personal-info">
		
	
		
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">First Name</label>
					<div id="first_name-value">
					{
						isLoading? <Skeleton/>:userData.first_name?userData.first_name:"Not specified"
					}
				
					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit">
					<span style={{cursor:'pointer'}} onClick={()=>{setInputFirstNameVisible(!inputFirstName)}} className="nav-link py-0" href="#first_name-collapse" data-bs-toggle="collapse">
						<i className="fi-edit"></i>
					</span>
				</div>
			</div>
			<div className={`collapse  ${inputFirstName?"show":""}`} id="first_name-collapse" data-bs-parent="#personal-info">
				<input name='first_name' value={newProfileData.first_name} onChange={handleChange} className="form-control mt-3" type="text" data-bs-binded-element="#first_name-value"  data-bs-unset-value="Not specified" />
			</div>

					</div>
		
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">Last Name</label>
					<div id="last_name-value">	{
						isLoading? <Skeleton/>:userData.last_name?userData.last_name:"Not specified"
					}
				</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit">
					<span  className="nav-link py-0" style={{cursor:'pointer'}} onClick={()=>{setInputLastNameVisible(!inputLastName)}} >
						<i className="fi-edit"></i>
					</span>
				</div>
			</div>
			<div  className={`collapse  ${inputLastName?"show":""}`} id="last_name-collapse" data-bs-parent="#personal-info">
				<input  name='last_name' value={newProfileData.last_name} onChange={handleChange} className="form-control mt-3" type="text" data-bs-binded-element="#last_name-value"  data-bs-unset-value="Not specified"/>
			</div>

					</div>
		
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">Email</label>
					<div id="email-value">
					{
						isLoading? <Skeleton/>:userData.email?userData.email:"Not specified"
					}
				

					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit">
					<span style={{cursor:'pointer'}} onClick={()=>{setInputEmailVisible(!inputEmail)}} className="nav-link py-0" href="#email-collapse" data-bs-toggle="collapse">
						<i className="fi-edit"></i>
					</span>
				</div>
			</div>
			<div className={`collapse  ${inputEmail?"show":""}`}  id="email-collapse" data-bs-parent="#personal-info">
				<input  name='email' value={newProfileData.email} onChange={handleChange} className="form-control mt-3" type="email" data-bs-binded-element="#email-value" data-bs-unset-value="Not specified" />
				<small>Changing the email will cause you to reverify it.</small>
			</div>

					</div>
	
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">Phone number</label>
					<div id="phone-value">
					{
						isLoading? <Skeleton/>:userData.phone_number?userData.phone_number:"Not specified"
					}

					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit" aria-label="Edit">
				<span style={{cursor:'pointer'}} onClick={()=>{setInputPhoneVisible(!inputPhone)}}  className="nav-link py-0" href="#phone-collapse" data-bs-toggle="collapse"><i className="fi-edit"></i></span></div>
			</div>
			<div className={`collapse  ${inputPhone?"show":""}`} id="phone-collapse" data-bs-parent="#personal-info">
				<input name='phone_number' value={newProfileData.phone_number} onChange={handleChange} className="form-control mt-3" type="number" data-bs-binded-element="#phone-value" data-bs-unset-value="Not specified" />
			</div>

					</div>
		
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">Company name</label>
					<div id="company-value">{
						isLoading? <Skeleton/>:userData.company_name?userData.company_name:"Not specified"
					}</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit" aria-label="Edit">
				<span style={{cursor:'pointer'}} onClick={()=>{setInputCompanyNameVisible(!inputCompanyName)}}   className="nav-link py-0" href="#company-collapse" data-bs-toggle="collapse"><i className="fi-edit"></i></span></div>
			</div>
			<div className={`collapse  ${inputCompanyName?"show":""}`} id="company-collapse" data-bs-parent="#personal-info">
				<input  name='company_name' value={newProfileData.company_name} onChange={handleChange}  className="form-control mt-3" type="text" data-bs-binded-element="#company-value" data-bs-unset-value="Not specified" placeholder="Enter company name" />
			</div>

					</div>
		
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">Address</label>
					<div id="address-value">

					{
						isLoading? <Skeleton/>:userData.address?userData.address:"Not specified"
					}

					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit" aria-label="Edit">
				<span style={{cursor:'pointer'}} onClick={()=>{setInputAddressVisible(!inputAddress)}}    className="nav-link py-0" href="#address-collapse" data-bs-toggle="collapse"><i className="fi-edit"></i></span></div>
			</div>
			<div className={`collapse  ${inputAddress?"show":""}`} id="address-collapse" data-bs-parent="#personal-info">
				<input  name='address' value={newProfileData.address} onChange={handleChange} className="form-control mt-3" type="text" data-bs-binded-element="#address-value" data-bs-unset-value="Not specified" placeholder="Enter address" />
			</div>

					</div>
		
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">Nationality</label>
					<div id="nationality-value">

					{
						isLoading? <Skeleton/>:userData.nationality?userData.nationality:"Not specified"
					}
					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit">
					<span style={{cursor:'pointer'}} onClick={()=>{setInputNationalityVisible(!inputNationality)}}  className="nav-link py-0" href="#nationality-collapse" data-bs-toggle="collapse"><i className="fi-edit"></i>
					</span>
				</div>
			</div>
			<div className={`collapse  ${inputNationality?"show":""}`} id="nationality-collapse" data-bs-parent="#personal-info">
				<input name='nationality' value={newProfileData.nationality} onChange={handleChange} className="form-control mt-3" type="text" data-bs-binded-element="#nationality-value" data-bs-unset-value="Not specified" placeholder="Enter nationality" />
			</div>

					</div>
		
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">ID Type</label>
					<div id="id_type-value">

					{
						isLoading? <Skeleton/>:userData.id_type?userData.id_type:"Not specified"
					}
					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit">
					<span style={{cursor:'pointer'}} onClick={()=>{setInputIDTypeVisible(!inputIDType)}}  className="nav-link py-0" href="#id_type-collapse" data-bs-toggle="collapse">
						<i className="fi-edit"></i>
					</span>
				</div>
			</div>
			<div className={`collapse  ${inputIDType?"show":""}`} id="id_type-collapse" data-bs-parent="#personal-info">
				<select  name='national_id_type' value={newProfileData.national_id_type} onChange={handleChange} className="form-control mt-3">
					<option value="">Select ID Type</option>
					<option value="national_id">National ID</option>
					<option value="passport">Passport</option>
					<option value="driving_license">Driving License</option>
					<option value="student_id">Student ID</option>
				</select>
			</div>
			
</div>
	
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">ID Number</label>
					<div id="id_number-value">
					{
						isLoading? <Skeleton/>:userData.id_number?userData.id_number:"Not specified"
					}

					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit">
					<span style={{cursor:'pointer'}} onClick={()=>{setInpuIDNumberVisible(!inputIDNumber)}}  className="nav-link py-0" href="#id_number-collapse" data-bs-toggle="collapse"><i className="fi-edit"></i>
					</span>
				</div>
			</div>
			<div  className={`collapse  ${inputIDNumber?"show":""}`} id="id_number-collapse" data-bs-parent="#personal-info">
				<input name='national_id_number' value={newProfileData.national_id_number} onChange={handleChange} className="form-control mt-3" type="text" data-bs-binded-element="#id_number-value" data-bs-unset-value="Not specified" placeholder="ID number" />
			</div>

					</div>


	
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">Sex</label>
					<div id="sex-value">
					{
						isLoading? <Skeleton/>:userData.sex?userData.sex:"Not specified"
					} 
					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit">
					<span  style={{cursor:'pointer'}} onClick={()=>{setInputSexVisible(!inputSex)}}  className="nav-link py-0" href="#sex-collapse" data-bs-toggle="collapse"><i className="fi-edit"></i>
					</span>
				</div>
			</div>
			<div className={`collapse  ${inputSex?"show":""}`} id="sex-collapse" data-bs-parent="#personal-info">
				<select  name='gender' value={newProfileData.gender} onChange={handleChange} className="form-control mt-3">
					<option value="">Select Sex</option>
					<option value="male">Male</option>
					<option value="female">Female</option>
				</select>
			</div>

					</div>
		
		<div className="border-bottom pb-3 mb-3">
			<div className="d-flex align-items-center justify-content-between">
				<div className="pe-2">
					<label className="form-label fw-bold">Emergency Contact</label>
					<div id="emergency_contact-value">
					{
						isLoading? <Skeleton/>:userData.emergencycontact?userData.emergencycontact:"Not specified"
					} 
					</div>
				</div>
				<div className="me-n3" data-bs-toggle="tooltip" title="" data-bs-original-title="Edit" aria-label="Edit">
				<span style={{cursor:'pointer'}} onClick={()=>{setInputEmergencyContactVisible(!inputEmergencyContact)}}  className="nav-link py-0" href="#emergency_contact-collapse" data-bs-toggle="collapse"><i className="fi-edit"></i></span></div>
			</div>
			<div className={`collapse  ${inputEmergencyContact?"show":""}`}  id="emergency_contact-collapse" data-bs-parent="#personal-info">
				<input name='emergency_contact' value={newProfileData.emergency_contact} onChange={handleChange} className="form-control mt-3" type="text" data-bs-binded-element="#emergency_contact-value" data-bs-unset-value="Not specified" />
			</div>

					</div>
	

	<div className="pt-2">
		<label className="form-label fw-bold mb-3">Socials</label>
	</div>

	<div className="d-flex align-items-center mb-3">
			<div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
				<i className="fi-whatsapp text-body"></i>
			</div>
			<input name='whatsapp_number' value={newProfileData.whatsapp_number} onChange={handleChange} className="form-control" type="text" placeholder="Your Whatsapp number" />
		</div>
	<div className="d-flex align-items-center mb-3">
		<div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
			<i className="fi-facebook text-body"></i>
		</div>
		<input name='facebook_link' value={newProfileData.facebook_link} onChange={handleChange} className="form-control" type="text" placeholder="Your Facebook account link" />
	</div>

	
	<div className="d-flex align-items-center mb-3">
		<div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
			<i className="fi-linkedin text-body"></i>
		</div>
		<input  name='linkedin_link' value={newProfileData.linkedin_link} onChange={handleChange} className="form-control" type="text" placeholder="Your LinkedIn account link" />
	</div>


	


	<div  class={`collapse ${showMore?"show":""}`} id="showMoreSocials" >
	<div className="d-flex align-items-center mb-3">
		<div className="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
			<i className="fi-twitter text-body"></i>
		</div>
		<input name='twitter_link' value={newProfileData.twitter_link} onChange={handleChange} className="form-control" type="text" placeholder="Your Twitter account link" />
	</div>

		<div class="d-flex align-items-center mb-3">
			<div class="btn btn-icon btn-light btn-xs shadow-sm rounded-circle pe-none flex-shrink-0 me-3">
				<i class="fi-instagram text-body"></i>
			</div>
			<input name='instagram_link' value={newProfileData.instagram_link} onChange={handleChange}   class="form-control" type="text" placeholder="Your Instagram account link" />
		</div>

		
	
		
	</div>


	<span style={{cursor:"pointer"}} onClick={()=>{setShowMore(!showMore)}}  >
		<i class={`${showMore?"fi-arrow-up":"fi-arrow-down "} me-2`}></i>
		{
			showMore?"show less":"show more"
		} 
	</span>
	

		
		

		
	

	<div className="d-flex align-items-center justify-content-between border-top mt-4 pt-4 pb-1">
		

		{
					isLoadingUpdate?<button type="button" class="btn btn-primary btn-primary px-3 px-sm-4  ">
  <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
   updating...
  
</button>: <button className="btn btn-primary px-3 px-sm-4" type="submit" >
			Save changes
		</button>

}
	

		<button className="btn btn-link btn-sm px-0 text-danger" data-bs-toggle="modal" data-bs-target="#deleteAccountModal">
			<i className="fi-trash me-2"></i>
			Delete account
		</button>
	</div>




</div>
		</form>


        </div>

	
	
  )
}

export default ProfilePage