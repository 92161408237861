
import React, { useEffect, useRef, useState } from 'react'

import Accra from "../../asserts/img/cities/accra.jpeg"
import Kumasi from '../../asserts/img/cities/kumasi.png'
import Takoradi from '../../asserts/img/cities/takoradi.jpg'
import Ho from "../../asserts/img/cities/ho.png"
import Tamale from "../../asserts/img/cities/tamale.jpeg"
import Sunyani from "../../asserts/img/cities/sunyani.png"
import Capecoast from "../../asserts/img/cities/capecoast.jpeg"
import Carousel from 'react-elastic-carousel';
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'


const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1, infinite: false, swipe: true, speed: 500 },
  { width: 500, itemsToShow: 2, itemsToScroll: 1, infinite: false, swipe: true, speed: 500 },
  { width: 768, itemsToShow: 4, itemsToScroll: 4,  infinite: false, swipe: true, speed: 500 }
];



function CitiesFilter() {
  const navigate=useNavigate()
  const dispatch=useDispatch()

  

const [currentIndex, setCurrentIndex] = useState(0);
const totalItems = 6; // Adjust this to the total number of items you have

const handleNextEnd = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};

const handlePrevEnd = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};

const handleChange = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};


const CustomArrow = ({ type, onClick, isEdge }) => {
  const isPrevArrow = type === 'PREV';
  const isNextArrow = type === 'NEXT';

  return (
    <button
      onClick={onClick}
      disabled={isEdge}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '14px'
      }}
    >
      {isPrevArrow ? <i className="fi-chevron-left"></i> : <i className="fi-chevron-right"></i>}
    </button>
  );
};

  return (
    <section className="container mb-5 pb-2">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h2 className="h3 mb-0">Search property by city</h2><a className="btn btn-link fw-normal ms-md-3 pb-0" href="real-estate-catalog-rent.html"></a>
        </div>
        <div className="tns-carousel-wrapper tns-controls-outside-xxl tns-nav-outside tns-nav-outside-flush mx-n2">
          <div className="tns-carousel-inner row gx-4 mx-0 py-md-4 py-3" data-carousel-options="{&quot;items&quot;: 4, &quot;responsive&quot;: {&quot;0&quot;:{&quot;items&quot;:1},&quot;500&quot;:{&quot;items&quot;:2},&quot;768&quot;:{&quot;items&quot;:3},&quot;992&quot;:{&quot;items&quot;:4}}}">


          <Carousel
      renderArrow={CustomArrow}
      onNextEnd={handleNextEnd}
        onPrevEnd={handlePrevEnd}
        onChange={handleChange}
     itemsToShow={3}
    itemsToScroll={2}
    showArrows={true}
    showDots={false}
    enableAutoPlay={false}
    autoPlaySpeed={1500}
    infinite={true}
    pagination={false}
    disableArrowsOnEnd={true}
    focusOnSelect={true}
    initialActiveIndex={0}
    breakPoints={breakPoints}
  
       >


<div className="col p-sm-2 mb-3 "><a className="card  card-hover border-0  shadow-lg" onClick={()=>{
  localStorage.setItem('city',"accra");
  navigate('/properties')}}  >
                <div className="card-img-top card-img-hover" style={{height:"200px"}}><span className="img-overlay opacity-65"></span>
           
                <img  src={Accra} alt="Accra" style={{width:"100%",height:"100%",objectFit:"cover"}}/>

                 
                </div>
                <div className="card-body text-center">
                  <h3 className="mb-0 fs-base text-nav">Accra</h3>
                </div></a></div>
         
            <div className="col p-2   mb-3  "><a className="card shadow-sm card-hover border-0 shadow-lg" 
            onClick={()=>{
  localStorage.setItem('city',"kumasi");
  navigate('/properties')}} 
            >
                <div className="card-img-top card-img-hover" style={{height:"200px"}}><span className="img-overlay opacity-65"></span><img src={Kumasi}alt="kumasi" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                
                </div>
                <div className="card-body text-center">
                  <h3 className="mb-0 fs-base text-nav">Kumasi</h3>
                </div></a></div>
          
            <div className="col p-2 mb-3 "><a className="card  shadow-lg shadow-sm card-hover border-0"  
            onClick={()=>{
  localStorage.setItem('city',"caoe coast");
  navigate('/properties')}} >
            <div className="card-img-top card-img-hover" style={{height:"200px"}}><span className="img-overlay opacity-65"></span><img src={Capecoast} alt="Los Angeles" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                
                </div>
                <div className="card-body text-center">
                  <h3 className="mb-0 fs-base text-nav">Cape Coast</h3>
                </div></a></div>
        
            <div className="col p-2 mb-3  "><a className="card shadow-lg card-hover border-0" 
            onClick={()=>{
  localStorage.setItem('city',"takoradi");
  navigate('/properties')}} >
                <div className="card-img-top card-img-hover" style={{height:"200px"}}><span className="img-overlay opacity-65"></span><img src={Takoradi} alt="takoradi" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                 
                </div>
                <div className="card-body text-center">
                  <h3 className="mb-0 fs-base text-nav">Takoradi</h3>
                </div></a></div>
       
            <div className="col p-2 mb-3 "><a className="card shadow-lg card-hover border-0" 
            onClick={()=>{
  localStorage.setItem('city',"ho");
  navigate('/properties')}} 
            >
                <div className="card-img-top card-img-hover" style={{height:"200px"}}><span className="img-overlay opacity-65"></span><img src={Ho} alt="ho" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                  
                </div>
                <div className="card-body text-center">
                  <h3 className="mb-0 fs-base text-nav">Ho</h3>
                </div></a></div>





                <div className="col p-2  mb-3"><a className="card shadow-lg card-hover border-0" 
                onClick={()=>{
  localStorage.setItem('city',"tamale");
  navigate('/properties')}} 
                >
                <div className="card-img-top card-img-hover" style={{height:"200px"}}><span className="img-overlay opacity-65"></span><img src={Tamale} alt="tamale" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                
                </div>
                <div className="card-body text-center">
                  <h3 className="mb-0 fs-base text-nav">Tamale</h3>
                </div></a></div>



                <div className="col p-2 mx-2 mb-3 "><a className="card shadow-lg card-hover border-0" 
                onClick={()=>{
  localStorage.setItem('city',"sunyani");
  navigate('/properties')}} 
                >
                <div className="card-img-top card-img-hover" style={{height:"200px"}}><span className="img-overlay opacity-65"></span><img src={Sunyani} alt="sunyani" style={{width:"100%",height:"100%",objectFit:"cover"}}/>
                
                </div>
                <div className="card-body text-center">
                  <h3 className="mb-0 fs-base text-nav">Sunyani</h3>
                </div></a></div>

       </Carousel>

        
           
          </div>
        </div>
      </section>
  )
}

export default CitiesFilter