import React, { useRef, useState } from 'react'
import logo from "../asserts/img/logo/site_logo.png"
import { Link ,useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../features/auth/authSlice'
import { useAddNewsletterMutation } from '../features/property/propertySlice'

function Footer() {
  const navigate=useNavigate()
  const token =useSelector(selectCurrentToken)
  const [newsletter, setNewsletter] = React.useState({
    email: ''
  })
  const [addnewsletter,{isLoading}]=useAddNewsletterMutation()
  const [success,setSuccess]=useState(false)
  const formRef=useRef()

const hadleAddNewsletter=async(e)=>{
  e.preventDefault()
  const isValid = formRef.current.checkValidity();
  if (isValid){
    try{
      await addnewsletter({newsletter})
      setNewsletter({ email:'' })
      setSuccess(true)
      
    } catch(error){
      console.log(error)
    }

  }
  else{
    formRef.current.classList.add("was-validated")


}
 
  
}

  return (
    <footer class="footer pt-lg-5 pt-4 bg-dark text-light">
    <div class="container mb-4 py-4 pb-lg-5">
      <div class="row gy-4">
        <div class="col-lg-3 col-md-6 col-sm-4">
          <div class="mb-4 pb-sm-3"><Link  class="d-inline-block" to='/'><img src={logo} width="116" alt="Logo"/></Link></div>
          <ul class="nav nav-light flex-column">
            <li class="nav-item mb-2"><a class="nav-link p-0 fw-normal text-light text-nowrap" href="mailto:support@accrashotstays.com"><i class="fi-mail mt-n1 me-1 align-middle text-primary"></i>support@accrashotstays.com</a></li>
            <li class="nav-item mb-2"><a class="nav-link p-0 fw-normal text-light text-nowrap" href="tel:4065550120"><i class="fi-device-mobile mt-n1 me-1 align-middle text-primary"></i>(+233) 247-070028</a></li>
          </ul>
        </div>
      
        <div class="col-lg-2 col-md-3 col-sm-4">
          <h3 class="fs-base text-light">Quick links</h3>
          <ul class="list-unstyled fs-sm">
            <li><a  class="nav-link-light"  style={{cursor:"pointer"}} onClick={()=>{navigate('/')}}>Home</a></li>
            <li><a  class="nav-link-light"  style={{cursor:"pointer"}}  onClick={()=>{navigate('/properties')}}>Find Accomodation </a></li>
         
            <li><a   class="nav-link-light"  style={{cursor:"pointer"}}  onClick={()=>{navigate('/help')}}>Support</a></li>
            <li><a   class="nav-link-light"   style={{cursor:"pointer"}} onClick={()=>{navigate('/blog')}}>Our Blog</a></li>

          </ul>
        </div>
       
        <div class="col-lg-2 col-md-3 col-sm-4">
          <h3 class="fs-base text-light">Profile</h3>
          <ul class="list-unstyled fs-sm">
            <li><a class="nav-link-light" onClick={()=>{navigate('/dashboard/personal-info')}} href="#">My account</a></li>
            {
              token?
              <li><a class="nav-link-light" onClick={()=>{navigate('/dashboard/wishlist')}} href="#">Favorites</a></li>

              :
              <li><a class="nav-link-light" onClick={()=>{navigate('/register')}} href="#">Sign up </a></li>
            }

           
          </ul>
        </div>
        
        <div class="col-lg-4 offset-lg-1">
          <h3 class="h4 text-light">Subscribe to our newsletter</h3>
          <p class="fs-sm mb-4 opacity-60">Don’t miss any relevant vacancies!</p>
          {
            success?   <div class="alert alert-success alert-dismissible fade show" role="alert"> <strong>Success!</strong>
			Subscription was successfully
			<button onClick={()=>{setSuccess(false)}} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
		</div>
            :
            ""

          }
       
          <form  onSubmit={hadleAddNewsletter} ref={formRef} class="form-group form-group-light rounded-pill needs-validation"  noValidate style={{maxwidth: '500px;'}}>
            <div class="input-group input-group-sm"><span class="input-group-text text-muted"><i class="fi-mail"></i></span>
              <input class="form-control" type="email" placeholder="Your email"/>
            </div>
            <button class="btn btn-primary btn-sm rounded-pill" type="submit">Subscribe</button>
          </form>
        </div>
      </div>
    </div>
    <div class="py-4 border-top border-light">
      <div class="container d-flex flex-column flex-lg-row align-items-center justify-content-between py-2">
      
        <p class="order-lg-1 order-2 fs-sm mb-2 mb-lg-0"><span class="text-light opacity-60">&copy; All rights reserved. Made by </span><a class="nav-link-light fw-bold" href="#" target="_blank" rel="noopener">Zelus Technologies</a></p>
        <div class="d-flex flex-lg-row flex-column align-items-center order-lg-2 order-1 ms-lg-4 mb-lg-0 mb-4">
        
          <div class="d-flex flex-wrap fs-sm mb-lg-0 mb-4 pe-lg-4"><Link to='/privacy-policy' class="nav-link-light px-2 mx-1" href="#">Privacy Policy</Link><Link to="/help" class="nav-link-light px-2 mx-1" href="#">Support</Link><Link to='/contact' class="nav-link-light px-2 mx-1" href="#">Contacts</Link></div>
          <div class="d-flex align-items-center">
           
           
            
            <div class="ms-4 ps-lg-2 text-nowrap"><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="#"><i class="fi-facebook"></i></a><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="#"><i class="fi-twitter"></i></a><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="#"><i class="fi-telegram"></i></a><a class="btn btn-icon btn-translucent-light btn-xs rounded-circle ms-2" href="#"><i class="fi-messenger"></i></a></div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer