import React ,{useEffect, useState} from 'react'
import Navbar from '../components/navbar'
import Hero from '../components/landingcomponents/hero'
import Categoriescarousel from '../components/landingcomponents/categoriescarousel'
import AvailableListing from '../components/landingcomponents/availableListing'
import TopListing from '../components/landingcomponents/topListing'
import InterestingPlaces from '../components/landingcomponents/interestingPlaces'
import Findavailable from '../components/landingcomponents/findavailable'
import PageLoading from '../components/landingcomponents/pageLoader';
import { Link } from 'react-router-dom'
import { selectCurrentUser,selectCurrentToken } from '../features/auth/authSlice'
import { useSelector } from 'react-redux'
import CitiesFilter from '../components/landingcomponents/citiesFilter'
import CitiesCarousel from '../components/landingcomponents/citiesCarousel'
import Layout2 from '../components/landingcomponents/layout2'
import { useGetSiteLayoutMutation } from '../features/property/propertySlice'
import HeroSkeleton from '../components/landingcomponents/heroSkeleton'
import GetLocation from '../components/getLocation'



function Accra_home() {
const user=useSelector(selectCurrentUser)
const token = useSelector(selectCurrentToken)
const [getLayout,{isLoading}]=useGetSiteLayoutMutation()
const [layout,setLayout]=useState()

 
const handleSiteLayout =async()=>{
  
  const response =await getLayout().unwrap()
  console.log('layout',response);
  setLayout(response)
}




useEffect(()=>{
  handleSiteLayout()
},[])

  return (
    <main >
    <GetLocation/>
    {
      isLoading&&<HeroSkeleton/>
    }
    {
      layout&&layout[0].layout==="1"?
      <Hero  layoutdata={layout[0]} />
      :
      ""


    }
    
    {
      layout&&layout[0].layout==="2"?
      <Layout2 layoutdata={layout[0]}/>
      :
      ""


    }

 
    
  
      
      <Categoriescarousel/>
      <AvailableListing/>
    
      <CitiesFilter/>
     
      <InterestingPlaces/>
      <Findavailable/>
      <PageLoading/>
      </main>
  )
}

export default Accra_home