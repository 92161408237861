import React,{useState} from 'react'
import hero from "../asserts/img/real-estate/illustrations/contact.svg"
import { useAddcontactMutation } from '../features/property/propertySlice'
import { Link } from 'react-router-dom';


function Contact() {

const [addcontact,{isLoading}]=useAddcontactMutation()
const [formData, setFormData] = useState({
    full_name: '',
    email: '',
    subject: '',
    message: '',
    phone_number:'',

  });
  const [validated, setValidated] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };



  const handleSubmit =async (e) => {
    e.preventDefault();
    // Perform validation
    if (e.currentTarget.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    } else {
      // Handle form submission logic
      console.log('Form submitted:', formData);
      try {


        const response =addcontact(formData).unwrap()
        console.log(response)
        setSuccess(true)

      }catch(e){
        console.log(e)
      }
    }
  };

  return (
    <div className="container-fluid mt-5 pt-5 p-0">
    
    <nav class="mb-3 pt-md-3" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item"><Link to='/' href="real-estate-home-v1.html">Home</Link></li>
            <li class="breadcrumb-item active" aria-current="page">Contact us</li>
          </ol>
        </nav>

        <section class="container mb-5 pb-2 pb-md-4 pb-lg-5">
        <div class="row align-items-md-start align-items-center gy-4">
          <div class="col-lg-5 col-md-6">
            <div class="mx-md-0 mx-auto mb-md-5 mb-4 pb-md-4 text-md-start text-center" style={{maxwidth:'416px'}}>
              <h1 class="mb-4">Get in touch!</h1>
              <p class="mb-0 fs-lg text-muted">Fill out the form and out team will try to get back to you within 24 hours.</p>
            </div><img class="d-block mx-auto" src={hero} alt="Illustration"/>
          </div>
          <div class="col-md-6 offset-lg-1">
            <div class="card border-0 bg-secondary p-sm-3 p-2">
              <div class="card-body m-1">

              {
                success?
                <div class="alert alert-success d-flex" role="alert"><i class="fi-check-circle lead me-2 me-sm-3"></i>
                      <div>Thank you for contacting us! We will get back to you shortly.</div>
                    </div>:""
              }
              <form className={`needs-validation ${validated ? 'was-validated' : ''}`} onSubmit={handleSubmit} noValidate>
      <div className="mb-4">
        <label className="form-label" htmlFor="c-name">Full Name</label>
        <input
          className="form-control form-control-lg"
          id="c-name"
          type="text"
          name="full_name"
          value={formData.full_name}
          onChange={handleChange}
          required
        />
        <div className="invalid-tooltip mt-1">Please, enter your name</div>
      </div>
      <div className="mb-4">
        <label className="form-label" htmlFor="c-email">Your Email</label>
        <input
          className="form-control form-control-lg"
          name="email"
          id="c-email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <div className="invalid-tooltip mt-1">Please, enter your email</div>
      </div>

      <div className="mb-4">
        <label className="form-label" htmlFor="c-email">Phone Number</label>
        <input
          className="form-control form-control-lg"
          name="phone_number"
          id="c-email"
          type="text"
          value={formData.phone_number}
          onChange={handleChange}
          required
        />
        <div className="invalid-tooltip mt-1">Please, enter your phone number</div>
      </div>

      <div className="mb-4">
        <label className="form-label" htmlFor="c-subject">Subject</label>
        <input
          className="form-control form-control-lg"
          name="subject"
          id="c-subject"
          type="text"
          value={formData.subject}
          onChange={handleChange}
          required
        />
        <div className="invalid-tooltip mt-1">Please, enter the subject</div>
      </div>


      
      <div className="mb-4">
        <label className="form-label" htmlFor="c-message">Message</label>
        <textarea
          className="form-control form-control-lg"
          name="message"
          id="c-message"
          rows="4"
          placeholder="Leave your message"
          value={formData.message}
          onChange={handleChange}
          required
        ></textarea>
        <div className="invalid-tooltip mt-1">Please, type your message</div>
      </div>
      <div className="pt-sm-2 pt-1">
      {
        isLoading?
        <button className="btn btn-lg btn-primary w-sm-auto w-100" type="submit">

        <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
  submitting...
        </button>
        :        <button className="btn btn-lg btn-primary w-sm-auto w-100" type="submit">Submit form</button>

      }
      </div>
    </form>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section class="container mb-5 pb-2 pb-md-4 pb-lg-5">
        <div class="row g-4">
          
          <div class="col-md-4"><a class="icon-box card card-hover h-100" href="mailto:support@shortstaybuddy.com">
              <div class="card-body">
                <div class="icon-box-media text-primary rounded-circle shadow-sm mb-3"><i class="fi-mail"></i></div><span class="d-block mb-1 text-body">Drop us a line</span>
                <h3 class="h4 icon-box-title mb-0 opacity-90">support@shortstaybuddy.com</h3>
              </div></a></div>
      
          <div class="col-md-4"><a class="icon-box card card-hover h-100" href="tel:4065550120">
              <div class="card-body">
                <div class="icon-box-media text-primary rounded-circle shadow-sm mb-3"><i class="fi-device-mobile"></i></div><span class="d-block mb-1 text-body">Call us any time</span>
                <h3 class="h4 icon-box-title mb-0 opacity-90">(233) 59-666-6795</h3>
              </div></a></div>
           
          
         
          <div class="col-md-4"><a class="icon-box card card-hover h-100" href="#">
              <div class="card-body">
                <div class="icon-box-media text-primary rounded-circle shadow-sm mb-3"><i class="fi-instagram"></i></div><span class="d-block mb-1 text-body">Follow us</span>
                <h3 class="h4 icon-box-title mb-0 opacity-90">@shortstaybuddy</h3>
              </div></a></div>
        </div>
      </section>
     </div>
  )
}

export default Contact