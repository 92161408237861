import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAddToWishlistMutation } from '../features/property/propertySlice';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'simplebar/dist/simplebar.css';
import ResizeObserver from 'resize-observer-polyfill';

window.ResizeObserver = ResizeObserver;

function PropertyCard({ property }) {
    const navigate = useNavigate();
    const [addToWishlist, { isLoading }] = useAddToWishlistMutation();
    const [isInWishlist, setIsInWishlist] = useState(property.wishlist);
    const [images, setImages] = useState(property.other_files);
    const [allimages, setFirstImages] = useState([property.files, ...images]);

    const handleAddToWishlist = async () => {
        try {
            await addToWishlist({ property_id: property.id }).unwrap();
            setIsInWishlist(!isInWishlist);
        } catch (e) {
            console.log(e);
        }
    };

    const handleNavigate = () => {
        navigate(`/properties/detail/${property.id}`);
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        swipe: true,
        touchMove: true,
        rows: 1,
        arrows: true,
        prevArrow: <button type="button" data-controls="prev" tabIndex="-1" aria-controls="tns3"><i className="fi-chevron-left"></i></button>,
        nextArrow: <button type="button" data-controls="next" tabIndex="-1" aria-controls="tns3"><i className="fi-chevron-right"></i></button>,
    };

    return (
        <div className="card shadow-lg card-hover border-0 h-100">
            <div className="card-img-top card-img-hover">
                <div className="img-overlay"></div>
                <div className="content-overlay end-0 top-0 pt-3 pe-3">
                    <button className="btn btn-icon btn-light btn-xs text-primary rounded-circle" onClick={handleAddToWishlist} type="button" data-bs-toggle="tooltip" data-bs-placement="left" title="" data-bs-original-title="Add to Wishlist">
                        <i className={isInWishlist ? 'fi-heart-filled' : 'fi-heart'}></i>
                    </button>
                </div>
                <Slider className='slider3' {...settings}>
                    {allimages && allimages.map((image, index) => (
                        <div className='' style={{height:"200px"}}>
                        <img
                            src={image.files ? image.files : image}
                            key={index}
                            alt="Property"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Adjusted here
                        />
                        </div>
                    ))}
                </Slider>
            </div>
            <div onClick={handleNavigate} className="card-body position-relative pb-3">
                <h3 className="h6 mb-2 fs-base">
                    <span onClick={handleNavigate} className="nav-link stretched-link">
                        {property.title.length > 19 ? property.title.slice(0, 19) + "..." : property.title}
                    </span>
                </h3>
                <p className="mb-2 fs-sm text-muted">{property.city}, Ghana</p>
                <div className="fw-bold"><i className="fi-cash mt-n1 me-2 lead align-middle opacity-70"></i>{property.pricing[0].currency} {property.pricing[0].price}</div>
            </div>
            <div className="card-footer d-flex align-items-center justify-content-center mx-3 pt-3 text-nowrap">
                <span className="d-inline-block mx-1 px-2 fs-sm">{property.bedroom}<i className="fi-bed ms-1 mt-n1 fs-lg text-muted"></i></span>
                <span className="d-inline-block mx-1 px-2 fs-sm">{property.bathroom}<i className="fi-bath ms-1 mt-n1 fs-lg text-muted"></i></span>
                <span className="d-inline-block mx-1 px-2 fs-sm">{property.parking_spot}<i className="fi-car ms-1 mt-n1 fs-lg text-muted"></i></span>
            </div>
        </div>
    );
}

export default PropertyCard;
