import React from 'react'
import { Link } from 'react-router-dom'
import "../asserts/css/theme.min.css"


function PrivacyPolicy() {
  return (
    <div className="container-fluid mt-5 pt-5 p-0">
    <div class="container ">
		<nav class="mb-3 pt-md-3" aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><Link  to='/'>Home</Link></li>
				<li class="breadcrumb-item active" aria-current="page">
					Privacy Policy
				</li>
			</ol>
		</nav>
	</div>



    <section class="container mb-5 pb-2">
		<div class="row align-items-center justify-content-center">
			
			<div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
				<h1 class="mb-4">Privacy Policy
				</h1>
				<p class="mb-4 pb-3 fs-lg">
					This page informs you of our policies regarding the collection, use and disclosure of
					Personal Information we receive from users of the Site.
				</p>
				<p>
					We use your Personal Information only for providing and improving the Site. By using the
					Site, you agree to the collection and use of information in accordance with this policy.
				</p>
			</div>
			<div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
				<h3 class="mb-4">
					Information Collection And Use
				</h3>
				<p class="mb-4 pb-3 fs-lg">
					While using our Site, we may ask you to provide us with certain personally identifiable
					information that can be used to contact or identify you.
				</p>
				<p>
					Personally identifiable information may include, but is not limited to your name ("Personal
					Information").
				</p>
			</div>
			<div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
				<h3 class="mb-4">
					Log Data
				</h3>
				<p class="mb-4 pb-3 fs-lg">
					Like many site operators, we collect information that your browser sends whenever you visit
					our Site ("Log Data").
				</p>
				<p>
					This Log Data may include information such as your computer's Internet Protocol ("IP")
					address, browser type, browser version, the pages of our Site that you visit, the time and
					date of your visit, the time spent on those pages and other statistics.
				</p>
				<p>
					In addition, we may use third party services such as Google Analytics that collect, monitor
					and analyze the data.
				</p>
			</div>
			<div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
				<h3 class="mb-4">
					Communications
				</h3>
				<p class="mb-4 pb-3 fs-lg">
					We may use your Personal Information to contact you with newsletters, marketing or
					promotional materials and other information.
				</p>
			</div>
			<div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
				<h3 class="mb-4">
					Cookies
				</h3>
				<p class="mb-4 pb-3 fs-lg">
					Cookies are files with small amount of data, which may include an anonymous unique
					identifier. Cookies are sent to your browser from a web site and stored on your computer's
					hard drive.
				</p>
				<p>
					Like many sites, we use "cookies" to collect information. You can instruct your browser to
					refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept
					cookies, you may not be able to use some portions of our Site.
				</p>
			</div>
			<div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
				<h3 class="mb-4">
					Security
				</h3>
				<p class="mb-4 pb-3 fs-lg">
					The security of your Personal Information is important to us, but remember that no method of
					transmission over the Internet, or method of electronic storage, is 100% secure. While we
					strive to use commercially acceptable means to protect your Personal Information, we
					cannot guarantee its absolute security.
				</p>
			</div>
			<div class="col-lg-12 col-md-5 col-sm-9 order-md-1 order-2 text-md-start text-center">
				<h3 class="mb-4">
					Changes To This Privacy Policy
				</h3>
				<p class="mb-4 pb-3 fs-lg">
					This Privacy Policy is effective as of Thursday, 1 January 2023 and will remain in effect
					except with respect to any changes in its provisions in the future, which will be in effect
					immediately after being posted on this page.
				</p>
				<p>
					We reserve the right to update or change our Privacy Policy at any time and you should
					check this Privacy Policy periodically. Your continued use of the Service after we post any
					modifications to the Privacy Policy on this page will constitute your acknowledgment of the
					modifications and your consent to abide and be bound by the modified Privacy Policy.
				</p>
				<p>
					If we make any material changes to this Privacy Policy, we will notify you either through the
					email address you have provided us, or by placing a prominent notice on our website.
				</p>
			</div>
		</div>
	</section>
    </div>
  )
}

export default PrivacyPolicy