import { createSlice } from "@reduxjs/toolkit";



const authSlice = createSlice({
    name:"auth",
    initialState:{
        user_id: localStorage.getItem('user_id')|| null,
        access:localStorage.getItem('access') || null,
        refresh:localStorage.getItem('refresh') || null,
        is_email_verified:localStorage.getItem('is_email_verified') || null,
        userdata:null,
        categorydata:null,
        city:null,
        usersdata:null,
        role:null

    
    },
    reducers:{
        setCredentials:(state,action)=>{
            const {access,refresh,is_email_verified,user_id}=action.payload;

            localStorage.setItem('access', access);
            localStorage.setItem('refresh', refresh);
            localStorage.setItem('is_email_verified',is_email_verified);
            localStorage.setItem('user_id', user_id);


            state.user_id=user_id;
            state.access=access;
            state.refresh=refresh;
            state.is_email_verified= is_email_verified;
            

        },
        setCity:(state,action)=>{
            const {city}=action.payload;
            state.city=city;
        },
        setRegisteredUser:(state,action)=>{

            const {userregistered,otp}=action.payload;
            state.userregistered=userregistered;
            state.otp=otp;

        },
        setUserData:(state,action)=>{

            const userdata=action.payload;
        
            state.userdata=userdata;

        },
        setUsersData:(state,action)=>{

            const usersdata=action.payload;
        
            state.usersdata=usersdata;

        },
        setCategoryData:(state,action)=>{

            const categorydata=action.payload;
        
            state.category=categorydata;

        }

        ,
        logOut:(state, action)=>{
            state.user_id = null
            state.access = null
            state.refresh=null
            state.is_email_verified=null;
            state.userdata=null;
            state.usersdata=null;
            state.categorydata=null;
            localStorage.clear();
            
        },
    }
})



export const {setCredentials,logOut,setRegisteredUser,setCategoryData,setUserData,setCity,setUsersData}=authSlice.actions
export default authSlice.reducer 
export const selectCurrentUser=(state)=>state.auth.user_id
export const selectCurrentToken=(state)=>state.auth.access
export const selectIsNumberVerified=(state)=>state.auth.is_email_verified
export const selectUserData=(state)=>state.auth.userdata
export const selectCategory=(state)=>state.auth.categorydata
export const selectCity=(state)=>state.auth.city
export const selectUsersData=(state)=>state.usersdata
