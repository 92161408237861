import React, { useState } from 'react'
import Sidebar from '../../components/profilecomponents/sidebar'
import { Link } from 'react-router-dom'
import "../../asserts/css/theme.min.css"

function Notifications() {
    const [toggleSaleNotifications,setSaleNotifications]=useState(false)
    const [toggleRentNotifications,setRentNotifications]=useState(true)

    const toggleRentNotificationsFunc=()=>{
        setRentNotifications(true);
        setSaleNotifications(false)
    }

    const toggleSaleNotificationsFunc=()=>{
        setRentNotifications(false);
        setSaleNotifications(true);
    }

  return (



  <div className="col-lg-8 col-md-7 mb-5">
				<div>

	<h1 className="h2">Notifications</h1>
	<p className="pt-1 mb-4">Get real-time updates on your favorite homes, neighborhoods, and more.</p>
	
	<ul  className="nav nav-tabs flex-column flex-sm-row align-items-stretch align-items-sm-start border-bottom mb-4" role="tablist">
		<li onClick={toggleRentNotificationsFunc} style={{cursor:"pointer"}} className="nav-item me-sm-3 mb-3">
			<span  className={`nav-link text-center ${toggleRentNotifications?"active":""}`} >
				Rent notifications
			</span>
		</li>
		<li  onClick={toggleSaleNotificationsFunc}  style={{cursor:"pointer"}} className="nav-item mb-3">
			<span className={`nav-link text-center ${toggleSaleNotifications?"active":""}`} >
				Sale notifications
			</span>
		</li>
	</ul>
	
	<div  className="tab-content py-2" id="notification-settings">
	
		<div className={`tab-pane fade ${toggleRentNotifications?"active show":""}`} id="notifications-rent" role="tabpanel">
			<div className="d-flex justify-content-between mb-4">
				<div className="me-2">
					<h6 className="mb-1">New rental alerts</h6>
					<p className="fs-sm mb-0">New rentals that match your <a href="https://shortstaybuddy.com/dashboard/wishlist">Wishlist</a></p>
				</div>
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="new-rental"/> 
					<label className="form-check-label" for="new-rental"></label>
				</div>
			</div>
			<div className="d-flex justify-content-between mb-4">
				<div className="me-2">
					<h6 className="mb-1">Rental status updates</h6>
					<p className="fs-sm mb-0">Updates like price changes and off-market status on your <a href="https://shortstaybuddy.com/dashboard/wishlist">Wishlist</a></p>
				</div>
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="rental-update" />
					<label className="form-check-label" for="rental-update"></label>
				</div>
			</div>
			<div className="d-flex justify-content-between mb-4">
				<div className="me-2">
					<h6 className="mb-1">Finder recommendations</h6>
					<p className="fs-sm mb-0">Rentals we think you'll like. These recommendations may be slightly outside your search
						criteria</p>
				</div>
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="rental-recomendation" />
					<label className="form-check-label" for="rental-recomendation"></label>
				</div>
			</div>
		</div>

		<div  className={`tab-pane fade ${toggleSaleNotifications?"active show":""}`} >
			<div className="d-flex justify-content-between mb-4">
				<div className="me-2">
					<h6 className="mb-1">New sale alerts</h6>
					<p className="fs-sm mb-0">New sales that match your <a href="https://shortstaybuddy.com/dashboard/wishlist">Wishlist</a></p>
				</div>
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="new-sale" />
					<label className="form-check-label" for="new-sale"></label>
				</div>
			</div>
			<div className="d-flex justify-content-between mb-4">
				<div className="me-2">
					<h6 className="mb-1">Sale status updates</h6>
					<p className="fs-sm mb-0">Updates like price changes and off-market status on your <a href="https://shortstaybuddy.com/dashboard/wishlist">Wishlist</a></p>
				</div>
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="sale-update" />
					<label className="form-check-label" for="sale-update"></label>
				</div>
			</div>
			<div className="d-flex justify-content-between mb-4">
				<div className="me-2">
					<h6 className="mb-1">Finder recommendations</h6>
					<p className="fs-sm mb-0">Sales we think you'll like. These recommendations may be slightly outside your search
						criteria</p>
				</div>
				<div className="form-check form-switch">
					<input className="form-check-input" type="checkbox" id="sale-recomendation" />
					<label className="form-check-label" for="sale-recomendation"></label>
				</div>
			</div>
		</div>
		<div className="d-flex justify-content-between mb-4">
			<div className="me-2">
				<h6 className="mb-1">Featured news</h6>
				<p className="fs-sm mb-0">News and tips you may be interested in</p>
			</div>
			<div className="form-check form-switch">
				<input className="form-check-input" type="checkbox" id="featured-news" />
				<label className="form-check-label" for="featured-news"></label>
			</div>
		</div>
		<div className="d-flex justify-content-between mb-4">
			<div className="me-2">
				<h6 className="mb-1">Finder extras</h6>
				<p className="fs-sm mb-0">Occasional notifications about new features to make finding the perfect rental easy</p>
			</div>
			<div className="form-check form-switch">
				<input className="form-check-input" type="checkbox" id="extras"/>
				<label className="form-check-label" for="extras"></label>
			</div>
		</div>
	</div>
	<div className="border-top pt-4">
		

		
		<button className="btn btn-primary">Save</button>
	</div>

</div>

		</div>

  )
}

export default Notifications