import React, { useEffect, useRef, useState } from 'react'
import { useGetEventDetailMutation } from '../features/property/eventSlice'
import { Link ,useLocation,useParams } from 'react-router-dom'
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { GoogleMap,MarkerF,useJsApiLoader, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import map from '../asserts/img/real-estate/single/map.jpg'
import marker from '../asserts/img/map/marker.png'
import { selectCurrentToken } from '../features/auth/authSlice'
import { useSelector } from 'react-redux';
import SigninModal from '../components/landingcomponents/signinModal';



const placesLibrary = ['places']

function EventDetail() {

const [event,setEvent]=useState()
const [getevent,{isLoading}]=useGetEventDetailMutation()
const [show,setShow]=useState(false)
const dropdownRef=useRef(null)
const [imagelength,setImageLength]=useState()
const [showmodal,setshowmodal]=useState(false)
const location = useLocation();
const modalRef = useRef(null)
const currentUrl = window.location.origin + location.pathname;
const [center,setcenter]=useState()
const [userLocation, setUserLocation] = useState(null);
const [directionsResponse, setDirectionsResponse] = useState(null);
const  token =useSelector(selectCurrentToken)
const [showSigninmodal,setShowSigninModal] = useState();
const [showbackdrop,setshowbackdrop] = useState();
const {id}=useParams();





const togglesigninmodal = () => {
  setShowSigninModal(!showSigninmodal)
  setshowbackdrop(!showbackdrop)
};





const handleGetEvent=async()=>{

    const response= await getevent(id)
    console.log(response.data)
    setEvent(response.data)
    setImageLength(response.data.other_image.slice(0,5).length)
    setcenter({
      lat: response.data.latitude,
      lng:response.data.longitude,
      });
  

}







const getUserLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          resolve(userLocation); // Resolve with the user's location
        },
        (error) => {
          console.error("Error getting user's location:", error);
          reject(error); // Reject if there's an error
        }
      );
    } else {
      console.error('Geolocation is not supported by this browser.');
      reject('Geolocation not supported');
    }
  });
};
const calculateRoute = async () => {
  try {
    setshowmodal(true); // Show the modal first

    // Fetch the user's location asynchronously
    const userLocation = await getUserLocation();
    setUserLocation(userLocation); // Update user location state

    if (userLocation && center) {
      const directionsService = new window.google.maps.DirectionsService();

      directionsService.route(
        {
          origin: userLocation,
          destination: center,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === window.google.maps.DirectionsStatus.OK) {
            setDirectionsResponse(result); // Update the directions state
          } else {
            console.error(`Error fetching directions: ${result}`);
          }
        }
      );
    }
  } catch (error) {
    console.error('Error fetching route:', error);
  }
};


useEffect(()=>{
  handleGetEvent()
  
  },[])
  
  

const shareLinks = [
    {
      href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-facebook fs-base opacity-75 me-2',
      text: 'Facebook',
    },
    {
      href: `https://twitter.com/intent/tweet?text=Check%20this%20out!&url=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-twitter fs-base opacity-75 me-2',
      text: 'Twitter',
    },
    {
      href: `https://wa.me/?text=${encodeURIComponent(currentUrl)}`,
      iconClass: 'fi-whatsapp fs-base opacity-75 me-2',
      text: 'WhatsApp',
    },
    {
      href: `https://telegram.me/share/url?url=${encodeURIComponent(currentUrl)}&text=Check%20this%20out!`,
      iconClass: 'fi-telegram fs-base opacity-75 me-2',
      text: 'Telegram',
    },
  ];

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };

  const options = { 
    year: 'numeric', 
    month: 'long', 
    day: 'numeric', 
    hour: '2-digit', 
    minute: '2-digit', 
    timeZoneName: 'short' 
  };
  

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  

  useEffect(() => {
    function handleClickOutside(event) {
      
  
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setshowmodal(false)
      }
    }
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  
  }, []); 
  
  const containerStyle = {
		width: '100%',
		height: '350px'
	  };
	  

    const { isLoaded } = useJsApiLoader({
      id: 'google-map-script',
      googleMapsApiKey: "AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg",
      libraries: placesLibrary,
      language:"en",
      region:"GH",
      })

      const openGoogleMaps = (location) => {
        if (location) {
          const { lat, lng } = location;
          const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`;
          window.open(googleMapsUrl, '_blank');
        }
      };
      
  

  return (
    <>
      <div className={`backdrop  ${showmodal?"show":""} `}>
      <div  class="modal fade show open-modal" id="modalDefault" tabindex="-1" style={{display: 'block'}} aria-modal="true" role="dialog">
            <div ref={modalRef} class="modal-dialog modal-lg" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Directions to {event&&event.title} </h4>
                  <button  onClick={()=>{setshowmodal(!showmodal)}} class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                {!isLoaded  ? (
                  <img className="rounded-3" src={map} alt="Map" />
                ) : (
                  <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
                    { userLocation?"": (center && <MarkerF position={center} icon={marker} />)}
                    {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
                  </GoogleMap>
                )}
         
             
          </div>
                <div class="modal-footer d-flex justify-content-between">
                <button className="btn btn-primary btn-sm" type="button" onClick={() => openGoogleMaps({lat:event&&event.latitude,lng:event&&event.longitude})}><i className='fi-map fi '></i>  Take Me There</button>

                  <button class="btn btn-secondary btn-sm" type="button" data-bs-dismiss="modal "  onClick={()=>{setshowmodal(!showmodal)}}>Close</button>

                 
                </div>
              </div>
            </div>
          </div>
          </div>
    
    <section class="container pt-5 mt-5">
   
    <nav class="mb-3 pt-md-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item">
                <Link to='/'>Home</Link>
            </li>
            <li class="breadcrumb-item">
                <Link to='/events' >
                    experiences 
                </Link>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
                { event&& event.title}
            </li>
        </ol>
    </nav>
    <div class="d-sm-flex align-items-center justify-content-between mb-4 pb-sm-2">
        <h1 class="h2 me-3 mb-sm-0"> { event&& event.title}</h1>
        <div class="text-nowrap">
     
            <div class="dropdown newdrpdown d-inline-block" data-bs-toggle="tooltip" title="" data-bs-original-title="Share">
                <button onClick={()=>{setShow(!show)}} class="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle ms-2" type="button" data-bs-toggle="dropdown">
                    <i class="fi-share"></i>
                </button>
                <div ref={dropdownRef} className={`dropdown-menu dropdown-menu-end my-1  ${show?"show":""}`}>
      {shareLinks.map((link, index) => (
        <a key={index} href={link.href} className="dropdown-item" target="_blank" rel="noopener noreferrer">
          <i className={link.iconClass}></i>
          {link.text}
        </a>
      ))}
    </div>
            </div>
        </div>
    </div>


    <section class="container overflow-auto mb-5" data-simplebar="init">
		<PhotoProvider 
            speed={() => 800}
  easing={(type) => (type === 2 ? 'cubic-bezier(0.36, 0, 0.66, -0.56)' : 'cubic-bezier(0.34, 1.56, 0.64, 1)')}
      >
    <div class="row g-2 g-md-3 gallery" data-thumbnails="true" style={{minWidth: '30rem'}}>
			<div class="col-12">
      <PhotoView     
             src={`https://api.shortstaybuddy.com/${event && event.featured_image}`}>
				<div class="gallery-item rounded rounded-md-3"  data-sub-html="<h6 class=&quot;fs-sm text-light&quot;>Sand Box</h6>" data-lg-id="85f2ac90-871a-46a1-b7ee-1d0186f40b2b">
        
					<img style={{height: '650px', objectFit: 'cover'}} src={`https://api.shortstaybuddy.com/${event && event.featured_image}`} alt="Gallery thumbnail"/>
          
				</div>
        </PhotoView>

        <div className=" row row-cols-4 mt-3  g-2 g-md-3">
            {
            event && 
        event.other_image[0]&&
        event.other_image.map((file,index)=>{

          return (
          

            <>
            <div className="col">

            {
              index>3?
              <PhotoView     
             src={`https://api.shortstaybuddy.com/${file.image}`}>
             <div
              className={`gallery-item ${index >=3 &&imagelength>4?"more-item ":""} rounded rounded-md-3 mb-2 mb-md-3`} >
              <img 
                style={{ height: '200px',display:"none", objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com/${file.image}`}
                alt="Gallery thumbnail" 
              />
                           

            </div>
            
            </PhotoView>
             :
             
              <PhotoView     
             src={`https://api.shortstaybuddy.com/${file.image}`}>
             <div
              className={`gallery-item ${index >=3 &&imagelength>4?"more-item ":""} rounded rounded-md-3 mb-2 mb-md-3`} >
              <img 
                style={{ height: '200px', objectFit: 'cover' }} 
                src={`https://api.shortstaybuddy.com/${file.image}`}
                alt="Gallery thumbnail" 
              />
                           

              
              {
                index >=3 &&imagelength>4?
                <span class="gallery-item-caption fs-base" style={{cursor:"pointer"}}>
                
                +{imagelength-4} <span class='d-none d-md-inline'>photos</span>
                
                </span>
                :""
              }
            </div>
            
            </PhotoView>
            }
               
      
              
              </div>

            </>
         ) })
          
          }
         
            </div>
			</div>


     
		</div>
    </PhotoProvider>
</section>
  

    <section class="container mt-5 ">
		<div class="row">
			<div class="col-md-12 mb-md-0 mb-3">
				<div class="card py-2 px-sm-4 px-3 shadow-sm">
					<div class="card-body mx-n2">
						
						<div class=" row  mb-3 pb-3 border-bottom">
							<div className=' col-sm-7 d-flex align-items-start'>
							<div class="ps-2 ms-1">
								<h3 class="h5 mb-2"> {event&&event.title}</h3>
								<ul class="list-unstyled d-flex flex-wrap fs-sm">
									
									<li class="me-2 mb-1 pe-1">
										<i class="fi-wallet mt-n1 me-1 align-middle opacity-70"></i>
                    ₵{event&&event.price} 
																			</li>
									<li class="me-2 mb-1 pe-1">
										<i class="fi-map-pin mt-n1 me-1 align-middle opacity-70"></i>
                                        {event&&event.location}
 
									</li>
                       
								</ul>
							</div>
              </div>
              <div className='col-sm-5' >            


               {
                  token?       
                  <button   onClick={calculateRoute}  className='btn btn-primary btn-sm mt-2 '><i className='fi-route '></i> Get Directions</button>

                  :
                  <button   onClick={togglesigninmodal}  className='btn btn-primary btn-sm mt-2 '><i className='fi-route '></i> Get Directions</button>



                }

              </div>
						</div>

            <div class="ps-2 ms-1 w-full  mb-3 pb-3 border-bottom" style={{width:"100%"}}>
            <div  class="row ">
									
									<div class="col-md-7 fs-dm ">
										<strong className='me-2'>Category:</strong>
                    {
      event && event.category_detail.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <span>{item.title}</span>
            {index !== event.category_detail.length - 1 && ", "}
          </React.Fragment>
        );
  })
}

																			</div>
                                      <div className='col-md-5 border-start '>
                                        <div className="ps-2 fs-sm d-flex flex-column ">
                                        <div><span><strong className='me-2'>Opening date:</strong>{event && `${new Date(event.start_date).toLocaleString('en-US', options)}` }</span></div>
                                      <div><span><strong className='me-3'>Closing date:</strong>{event && `${new Date(event.end_date).toLocaleString('en-US', options)}`}</span></div>


                                        </div>
                                      </div>
								
                  
								</div>


</div>
					
						<div class="mb-3 ps-2 pb-3 border-bottom ">
							<h4 class="h5 mb-2">Description</h4>
							<p class="fs-sm text-muted mb-0"></p><p>

                            {event&&event.description}

                            </p><p></p>
						</div>
						
						
					</div>
				</div>
			</div>
			
			
		</div>
	</section>

    <ul class="nav nav-pills border-bottom pb-3 mb-4">
    </ul>
</section>
      <SigninModal changeshow={togglesigninmodal}  show={showSigninmodal} backdrop={showbackdrop} />

</>
  )
}

export default EventDetail