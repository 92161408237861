import React from 'react'
import Sidebar from '../../components/profilecomponents/sidebar'
import { Link } from 'react-router-dom'
import "../../asserts/css/theme.min.css"

function  RefundRequest() {
  return (
  

  <div className="col-lg-8 col-md-7 mb-5 ">

<div className="card card card-body border-0 shadow-sm pb-1 me-lg-1">
    <div className="pt-2">
        <label className="form-label fw-bold mb-3">Available Transactions</label>
        
    </div>

                        <table className="table ">
        <thead>
            <tr>
                <th scope="col">Payout No</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Action</th>
            </tr>
        </thead>
        <tbody>
                                            <tr>
                    <td colspan="5" className="text-center">No Payouts at the moment!</td>
                </tr>
                                    </tbody>
    </table>
</div>


</div>



  )
}

export default  RefundRequest