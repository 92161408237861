import React from 'react'
import Sidebar from '../../../components/partners/basic/sidebar'

import Navbar from '../../../components/partners/basic/navbar'
import { Link } from 'react-router-dom'

function Commision() {
  return (
    <>
    <Navbar/>
       <Sidebar />
      
       <div className="content-page px-2  " id="content-page">
   


                          <div class="container">
                          <nav aria-label="breadcrumb">
                              <ol class="breadcrumb bg-primary mb-0">
                                 <li class="breadcrumb-item" style={{marginTop:"5px"}}><Link   to='/partner' class="text-white " ><i class="ri-home-4-line mr-1 float-left "></i> <span >Dasboard </span> </Link></li>
                                 <li class="breadcrumb-item" > </li> <li style={{marginTop:"5px"}} ><Link to='/partner/commision' href="#" class="text-white"  style={{marginTop:"5px"}} >commision</Link></li>
                               
                              </ol>
                           </nav>

                          <div className='row mt-3'>
      <div class="col-md-6 col-lg-4 d-flex justify-content-center align-items-center">
      <button type="button" class="btn btn-primary btn-block mb-3 w-full"> <i class="ri-wallet-2-line"></i> Withdraw Funds</button>
      </div>
       <div class="col-md-6 col-lg-4">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden" style={{position: "relative"}}>
                        <div class="iq-card-body pb-4">
                           <div class="rounded-circle iq-card-icon iq-bg-primary"><i class="ri-exchange-dollar-fill"></i></div>
                           <span class="float-right line-height-6">
                           <h5>Available Commision</h5></span>
                           <div class="clearfix"></div>
                           <div class="text-center">
                              <h3 class="mb-0"><span class="counter">GH₵  65</span></h3>
                           </div>
                        </div>
                       
                    
                    </div>
                  </div>

                  <div class="col-md-6 col-lg-4">
                     <div class="iq-card iq-card-block iq-card-stretch iq-card-height overflow-hidden" style={{position: "relative"}}>
                        <div class="iq-card-body pb-4">
                           <div class="rounded-circle iq-card-icon iq-bg-warning"><i class="ri-exchange-dollar-fill"></i></div>
                           <span class="float-right line-height-6">
                           <h5>Pending Commision</h5></span>
                           <div class="clearfix"></div>
                           <div class="text-center">
                              <h3 class="mb-0"><span class="counter">GH₵  65</span></h3>
                           </div>
                        </div>
                       
                    
                    </div>
                  </div>
                  </div>

                          <div class="iq-card-body">
                           <h4>Report Table</h4>
                           <table class="table table-striped">
                              <thead>
                                 <tr>
                                    <th scope="col">Customer's Name</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Amount Paid</th>
                                    <th scope="col">Year Commision</th>
                                    <th scope="col">Promo Code</th>

                                 </tr>
                              </thead>
                              <tbody>
                                 <tr>
                                    <th scope="row">Mark</th>
                                    <td>10/08/24</td>
                                    <td>GH₵ 20,00</td>
                                    <td>GH₵ 600</td>
                                    <td>Manager 90</td>

                                 </tr>
                                
                               
                              </tbody>
                           </table>
                         
                        </div>
                          </div>
                          </div>
                          </>
  )
}

export default Commision