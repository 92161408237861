import { createApi,fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials,logOut } from "../../features/auth/authSlice";
import queryString from 'query-string'



const apiUrl =process.env.REACT_APP_API_URL
console.log(apiUrl)

const baseQuery=fetchBaseQuery({
    baseUrl: `${apiUrl}`,
    credentials: 'include',
  
  
    
   
    prepareHeaders:(headers,{getState})=>{
        const token =getState().auth.access

        if (token) {
            headers.set('authorization',`JWT ${token}`,)

        }
    
        return headers
    }
})


const BaseQueryWithReauth=async (args,api,extraOptions)=>{
    let result = await baseQuery(args,api,extraOptions)
    
if (result?.error?.status === 401){
       
          
       
        const refreshTokenResponse =await  fetch(`${apiUrl}auth/refresh-token/`, {
            
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              
             
            },
            body:JSON.stringify({
                'refresh':localStorage.getItem('refresh')

            })
               
            
          });
          const refreshToken = await refreshTokenResponse.json();
 
        if (refreshToken?.access){

            const user=api.getState().auth.user_id;
            const is_email_verified=api.getState().auth.is_email_verified;
            const refresh=api.getState().auth.refresh;


            //store user
            api.dispatch(setCredentials({access:refreshToken.access,refresh:refresh,user_id:user,is_email_verified:is_email_verified}));
            //retry original request with new access token
            result=await baseQuery(args,api,extraOptions)
        } 
        else{
            api.dispatch(logOut())
        }

    }

   return result
}


export const apiSlice=createApi({
    baseQuery: BaseQueryWithReauth,
    endpoints:builder=>({})
})