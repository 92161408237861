import React, { useState } from 'react'
import Sidebar from '../../components/profilecomponents/sidebar'
import { Link } from 'react-router-dom'
import "../../asserts/css/theme.min.css"

function Reviews() {
const [toggleReviews,setToggleReviews]=useState(false)



  return (


  <div className="col-lg-8 col-md-7 mb-5">
				<div >

	<h1 className="h2">Reviews</h1>
	<p className="pt-1 mb-4">Reviews you’ve received will be visible both here and on your public profile.</p>
	
	<ul  className={`nav nav-tabs flex-column flex-sm-row align-items-stretch align-items-sm-start border-bottom mb-4 `} role="tablist">
		<li  style={{cursor:"pointer"}} onClick={()=>{setToggleReviews(false)}} className="nav-item me-sm-3 mb-3">
			<span  className={`nav-link text-center ${toggleReviews?"":"active"}`} >
				Reviews about you
			</span>
		</li>
		<li className="nav-item mb-3" style={{cursor:"pointer"}} onClick={()=>{setToggleReviews(true)}}>
			<span className={`nav-link text-center ${toggleReviews?"active":""}`}   >
				Reviews by you
			</span>
		</li>
	</ul>

	<div className="tab-content pt-2">
		
		<div className={`tab-pane fade ${toggleReviews?"":"active show "}`} id="reviews-about-you" role="tabpanel">
			<div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch justify-content-between pb-4 mb-2 mb-md-3">
				<h3 className="h4 mb-sm-0">
					<i className="fi-star-filled mt-n1 me-2 lead align-middle text-warning"></i>
										0
					(0
					review's)
				</h3>
				<div className="d-flex align-items-center ms-sm-4">
					<label className="fs-sm me-2 pe-1 text-nowrap" for="review-sorting1"><i className="fi-arrows-sort text-muted mt-n1 me-2"></i>Sort by:</label>
					<select className="form-select form-select-sm" id="review-sorting1">
						<option value="date-desc" selected="">
							Newest
						</option>
						<option value="date-asc">
							Oldest
						</option>
						<option value="rating-asc">
							Low rating
						</option>
						<option value="rating-desc">
							High rating
						</option>
					</select>
				</div>
			</div>
							<div className="mb-4 pb-4 border-bottom">
					<p>
						No review here at the momwent.
					</p>
				</div>
					
			<nav className="mt-2" aria-label="Reviews pagination">
				<ul className="pagination">
					<div>
	</div>

				</ul>
			</nav>
		</div>
	
		<div  className={`tab-pane fade ${toggleReviews?"active show":" "}`}  id="reviews-by-you" role="tabpanel">
			<div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-stretch justify-content-between pb-4 mb-2 mb-md-3">
				<h3 className="h4 mb-sm-0">
					0
					reviews
				</h3>
				<div className="d-flex align-items-center ms-sm-4">
					<label className="fs-sm me-2 pe-1 text-nowrap" for="review-sorting2"><i className="fi-arrows-sort text-muted mt-n1 me-2"></i>Sort by:</label>
					<select className="form-select form-select-sm" id="review-sorting2" >
						<option value="date-desc" selected="">
							Newest
						</option>
						<option value="date-asc">
							Oldest
						</option>
						<option value="rating-asc">
							Low rating
						</option>
						<option value="rating-desc">
							High rating
						</option>
					</select>
				</div>
			</div>
							<div className="mb-4 pb-4 border-bottom">
					<p>
						No review here at the momwent.
					</p>
				</div>
					</div>
	</div>

</div>
	</div>

  )
}

export default Reviews