import React, { useEffect, useRef, useState } from 'react'
import { useGetCategoriesMutation } from '../../features/property/propertySlice'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCategoryData } from '../../features/auth/authSlice'

import Carousel from 'react-elastic-carousel';


const breakPoints = [
  { width: 1, itemsToShow: 1, itemsToScroll: 1, infinite: false, swipe: true, speed: 500 },
  { width: 500, itemsToShow: 2, itemsToScroll: 1, infinite: false, swipe: true, speed: 500 },
  { width: 768, itemsToShow: 4, itemsToScroll: 4,  infinite: false, swipe: true, speed: 500 }
];

function Categoriescarousel() {

const [getcategories,{isLoading}]=useGetCategoriesMutation()
const [categories,setCategories]=useState()
const navigate=useNavigate()
const sliderRef=useRef()

const colors = ['warning', 'success', 'primary', 'accent', 'info'];
const dispatch=useDispatch()
const [windowWidth, setWindowWidth] = useState(window.innerWidth);



useEffect(() => {
  // Handler to call on window resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // Add event listener
  window.addEventListener('resize', handleResize);

  // Call handler right away so state gets updated with initial window size
  handleResize();

  // Remove event listener on cleanup
  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);


const handleGetCategories=async()=>{
  try{

    const categories= await getcategories()
    console.log(categories)
    setCategories(null)
    setCategories(categories.data)
    dispatch(setCategoryData(categories.data))

  }catch(e){
    console.log(e)

  }
}

useEffect(()=>{
  handleGetCategories()
},[])








const [currentIndex, setCurrentIndex] = useState(0);
const totalItems = 6; // Adjust this to the total number of items you have

const handleNextEnd = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};

const handlePrevEnd = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};

const handleChange = (currentItem, pageIndex) => {
  setCurrentIndex(pageIndex);
};


const CustomArrow = ({ type, onClick, isEdge }) => {
  const isPrevArrow = type === 'PREV';
  const isNextArrow = type === 'NEXT';

  return (
    <button
      onClick={onClick}
      disabled={isEdge}
      style={{
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        fontSize: '12px'
      }}
    >
      {isPrevArrow ? <i class="fi-chevron-left"></i> : <i class="fi-chevron-right"></i>}
    </button>
  );
};

  return (
    <div  className="container d-flex flex-wrap flex-column  flex-sm-row pb-5 mb-md-3 justify-content-center align-content-center ">
  
    {
      isLoading? 
     
      windowWidth < 868 && windowWidth > 600 ? (
        <>
        <div  className='d-flex flex-row  justify-content-center align-content-center' >
        <span   className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
      <Skeleton circle={true}  style={{width:"40px",height:"40px",marginRight:"5px"}}/>
      <Skeleton style={{width:"100px"}}/></span>

      <span   className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
      <Skeleton circle={true}  style={{width:"40px",height:"40px",marginRight:"5px"}}/>
      <Skeleton style={{width:"100px"}}/></span>
      </div>
      </>

      ):  windowWidth <600 ? (
        <>
        <div  className='d-flex flex-row  justify-content-center align-content-center' >
        <span   className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
      <Skeleton circle={true}  style={{width:"40px",height:"40px",marginRight:"5px"}}/>
      <Skeleton style={{width:"100px"}}/></span>

    
      </div>
      </>

      ): windowWidth >868 ? (
        <>
        <div  className='d-flex flex-row  justify-content-center align-content-center' >
        <span   className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
      <Skeleton circle={true}  style={{width:"40px",height:"40px",marginRight:"5px"}}/>
      <Skeleton style={{width:"100px"}}/></span>

      <span   className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
      <Skeleton circle={true}  style={{width:"40px",height:"40px",marginRight:"5px"}}/>
      <Skeleton style={{width:"100px"}}/></span>
      <span   className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
      <Skeleton circle={true}  style={{width:"40px",height:"40px",marginRight:"5px"}}/>
      <Skeleton style={{width:"100px"}}/></span>
      <span   className="icon-box card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
      <Skeleton circle={true}  style={{width:"40px",height:"40px",marginRight:"5px"}}/>
      <Skeleton style={{width:"100px"}}/></span>
    
      </div>
      </>

      ):""

      
      :
      
     <Carousel
      renderArrow={CustomArrow}
      onNextEnd={handleNextEnd}
        onPrevEnd={handlePrevEnd}
        onChange={handleChange}
     itemsToShow={3}
    itemsToScroll={2}
    showArrows={true}
    showDots={false}
    enableAutoPlay={false}
    autoPlaySpeed={1500}
    infinite={true}
    pagination={false}
    disableArrowsOnEnd={true}
    focusOnSelect={true}
    initialActiveIndex={0}
    breakPoints={breakPoints}
       >
     {
      categories&& categories.map((category,index)=>{
        return(
         
          
          <div style={{width:"97%"}} className='d-fle justify-content-center align-content-center mt-3'  >
  <div onClick={()=>{
  localStorage.setItem('category_name',category.name);
  navigate('/properties')}} key={category}   className="icon-box px-5 card flex-row align-items-center flex-shrink-0 card-hover border-0 shadow-sm rounded-pill py-2 ps-2 pe-4 mb-2 mb-sm-3 me-sm-3 me-xxl-4" >
      <div className={`icon-box-media bg-faded-${colors[index % colors.length]} text-${colors[index % colors.length]} rounded-circle me-2`}><i className={`${category.icon}`}></i></div>
      <strong className="icon-box-title fs-sm ps-1 pe-2 mb-0">{category.name} </strong></div>
      </div>
          

        )
      })
    }
  
   
</Carousel>
  }
    
  </div>
  )
}

export default Categoriescarousel