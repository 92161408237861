import React, { useEffect, useState } from 'react'
import Sidebar from '../../../components/partners/basic/sidebar'

import Navbar from '../../../components/partners/basic/navbar'
import { Link } from 'react-router-dom'
import { useUserPromocodeMutation } from '../../../features/partners/partnersSlice'
import { selectCurrentUser } from '../../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'
import CountUp from 'react-countup'

function Promo_Codes() {
   const [getuserpromocode,{isLoading}]=useUserPromocodeMutation()
   const user_id=useSelector(selectCurrentUser)
   const [promocodes,setPromoCodes]=useState()

   const handleGetuserpromocode=async()=>{
    try{
      const response=await getuserpromocode({user_id:user_id}).unwrap()
      console.log(response)
      setPromoCodes(response.results)
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
   handleGetuserpromocode()
  },[])

  const getDaysInterval = (start, end) => {
   const startDate = new Date(start);
   const endDate = new Date(end);
   const timeDiff = endDate - startDate;
   return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert from milliseconds to days
 };

 // Function to format dates
 const formatDate = (date) => {
   const options = { year: 'numeric', month: 'long', day: 'numeric' };
   return new Date(date).toLocaleDateString(undefined, options);
 };
  return (
    <>
    <Navbar/>
       <Sidebar />
      
       <div className="content-page px-2  " id="content-page">
   


                          <div class="container">

                          <nav aria-label="breadcrumb">
                              <ol class="breadcrumb bg-primary mb-0">
                                 <li class="breadcrumb-item" style={{marginTop:"5px"}}><Link   to='/partner' class="text-white " ><i class="ri-home-4-line mr-1 float-left "></i> <span >Dasboard </span> </Link></li>
                                 <li class="breadcrumb-item" > </li> <li style={{marginTop:"5px"}} ><Link to='/partner/promo_codes' href="#" class="text-white"  style={{marginTop:"5px"}} >Promo Codes</Link></li>
                               
                              </ol>
                           </nav>
                          <div className='row mt-3'>
                   
                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                        <button  type="button" class="btn btn-primary  btn-block  "><i class="ri-check-double-line"></i> Active Promo Codes</button>
                        
                    

                        </div>

                        <div className='col-sm-6 d-flex justify-content-center align-items-center'>
                        <button  type="button" class="btn btn-primary  btn-block  "><i class="ri-close-circle-fill"></i>  Expired Promo Codes</button>
                        
                    

                        </div>


                  </div>

                         <div className='row mt-5'>



<div className='col-12'>
<div class="iq-card">
                        <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h4 class="card-title">Promo Code List</h4>
                           </div>
                        </div>
                        <div class="iq-card-body">
                           <table class="table">
                              <thead>
                                 <tr >
                                    <th scope="col">Name</th>
                                    <th scope="col">Percentage</th>

                                    <th scope="col text" className='text-center'>Days Left</th>
                                   
                                 </tr>
                              </thead>
                              <tbody>

                              {

                                 isLoading?
                                 <>
                                 <tr style={{width:"100%"}}>
                                 <td colSpan="7"><Skeleton style={{height:"20px",width:"100%"}}/></td>
                                 
                                 </tr>
                                 <tr style={{width:"100%"}}>
                                 <td colSpan="7"><Skeleton style={{height:"20px",width:"100%"}}/></td>
                                 
                                 </tr>

                                    <tr style={{width:"100%"}}>
                                 <td colSpan="7"><Skeleton style={{height:"20px",width:"100%"}}/></td>
                                 
                                 </tr>

                                    <tr style={{width:"100%"}}>
                                 <td colSpan="7"><Skeleton style={{height:"20px",width:"100%"}}/></td>
                                 
                                 </tr>
                                       
                                 
                                    



                                 </>
                                 :

                                 promocodes&&promocodes.map((code)=>{
                                    return(
                                       <tr>
                                    <th scope="row">{code.name}</th>
                                    <td>{code.percentage}%</td>
                                    <td className='text-center'>
                                       <h5>{getDaysInterval(code.start_date,code.end_date)}</h5>
                                       <h6>{formatDate(code.start_date)}-{formatDate(code.end_date)}</h6>

                                    </td>
                                 </tr>
                                    )
                                 })

                              }
                                 

                                
                                
                              
                              </tbody>
                           </table>
                        </div>
                     </div>
</div>


                       

                         </div>
                          </div>
                          </div>
                          </>
  )
}

export default Promo_Codes