import React from 'react'

function SidebarAccomodation() {
  return (
    <aside className="col-lg-4 col-xl-3 border-top-lg border-end-lg shadow-sm px-3 px-xl-4 px-xxl-5 pt-lg-2">
    <div className="offcanvas offcanvas-start offcanvas-collapse" id="filters-sidebar">
      <div className="offcanvas-header d-flex d-lg-none align-items-center">
        <h2 className="h5 mb-0">Filters</h2>
        <button className="btn-close" type="button" data-bs-dismiss="offcanvas"></button>
      </div>
      
      <div className="offcanvas-body py-lg-4">
        <div className="pb-4 mb-2">
          <h3 className="h6">Address</h3>
          <select className="form-select mb-5">
            <option value="" disabled>Choose city</option>
            <option value="Chicago">Chicago</option>
            <option value="Dallas">Dallas</option>
            <option value="Los Angeles">Los Angeles</option>
            <option value="New York" selected>New York</option>
            <option value="San Diego">San Diego</option>
          </select>

          <h3 className="h6">Region</h3>

          <select className="form-select">
            <option value="" selected disabled>Choose Region</option>
            <option value="Brooklyn">Brooklyn</option>
            <option value="Manhattan">Manhattan</option>
            <option value="Staten Island">Staten Island</option>
            <option value="The Bronx">The Bronx</option>
            <option value="Queens">Queens</option>
          </select>
        </div>
        <div className="pb-4 mb-2">
          <h3 className="h6">Property category</h3>
          <div className="overflow-auto" data-simplebar data-simplebar-auto-hide="false" style={{height: '11rem'}}>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="house"/>
              <label className="form-check-label fs-sm" for="house">House</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="apartmen" />
              <label className="form-check-label fs-sm" for="apartment">Apartment</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="room"/>
              <label className="form-check-label fs-sm" for="room">Room</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="office"/>
              <label className="form-check-label fs-sm" for="office">Office</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="commercial"/>
              <label className="form-check-label fs-sm" for="commercial">Commercial</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="land"/>
              <label className="form-check-label fs-sm" for="land">Land</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="daily"/>
              <label className="form-check-label fs-sm" for="daily">Daily rental</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="new-building"/>
              <label className="form-check-label fs-sm" for="new-building">New building</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="parking-lot"/>
              <label className="form-check-label fs-sm" for="parking-lot">Parking lot</label>
            </div>
          </div>
        </div>
        <div className="pb-4 mb-2">
          <h3 className="h6">Price per month</h3>
          <div className="range-slider" data-start-min="1100" data-start-max="3000" data-min="200" data-max="5000" data-step="100">
            <div className="range-slider-ui"></div>
            <div className="d-flex align-items-center">
              <div className="w-50 pe-2">
                <div className="input-group"><span className="input-group-text fs-base">$</span>
                  <input className="form-control range-slider-value-min" type="text"/>
                </div>
              </div>
              <div className="text-muted">&mdash;</div>
              <div className="w-50 ps-2">
                <div className="input-group"><span className="input-group-text fs-base">$</span>
                  <input className="form-control range-slider-value-max" type="text"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-4 mb-2">
          <h3 className="h6 pt-1">Beds &amp; baths</h3>
          <label className="d-block fs-sm mb-1">Bedrooms</label>
          <div className="btn-group btn-group-sm" role="group" aria-label="Choose number of bedrooms">
            <input className="btn-check" type="radio" id="studio" name="bedrooms"/>
            <label className="btn btn-outline-secondary fw-normal" for="studio">Studio</label>
            <input className="btn-check" type="radio" id="bedrooms-1" name="bedrooms" checked/>
            <label className="btn btn-outline-secondary fw-normal" for="bedrooms-1">1</label>
            <input className="btn-check" type="radio" id="bedrooms-2" name="bedrooms"/>
            <label className="btn btn-outline-secondary fw-normal" for="bedrooms-2">2</label>
            <input className="btn-check" type="radio" id="bedrooms-3" name="bedrooms"/>
            <label className="btn btn-outline-secondary fw-normal" for="bedrooms-3">3</label>
            <input className="btn-check" type="radio" id="bedrooms-4" name="bedrooms"/>
            <label className="btn btn-outline-secondary fw-normal" for="bedrooms-4">4+</label>
          </div>
          <label className="d-block fs-sm pt-2 my-1">Bathrooms</label>
          <div className="btn-group btn-group-sm" role="group" aria-label="Choose number of bathrooms">
            <input className="btn-check" type="radio" id="bathrooms-1" name="bathrooms"/>
            <label className="btn btn-outline-secondary fw-normal" for="bathrooms-1">1</label>
            <input className="btn-check" type="radio" id="bathrooms-2" name="bathrooms"/>
            <label className="btn btn-outline-secondary fw-normal" for="bathrooms-2">2</label>
            <input className="btn-check" type="radio" id="bathrooms-3" name="bathrooms"/>
            <label className="btn btn-outline-secondary fw-normal" for="bathrooms-3">3</label>
            <input className="btn-check" type="radio" id="bathrooms-4" name="bathrooms"/>
            <label className="btn btn-outline-secondary fw-normal" for="bathrooms-4">4</label>
          </div>
        </div>
        <div className="pb-4 mb-2">
          <h3 className="h6 pt-1">Square metres</h3>
          <div className="d-flex align-items-center">
            <input className="form-control w-100" type="number" min="20" max="500" step="10" placeholder="Min"/>
            <div className="mx-2">&mdash;</div>
            <input className="form-control w-100" type="number" min="20" max="500" step="10" placeholder="Max"/>
          </div>
        </div>
        <div className="pb-4 mb-2">
          <h3 className="h6">Amenities</h3>
          <div className="overflow-auto" data-simplebar data-simplebar-auto-hide="false" style={{height: "11rem"}}>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="air-condition" checked/>
              <label className="form-check-label fs-sm" for="air-condition">Air conditioning</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="balcony"/>
              <label className="form-check-label fs-sm" for="balcony">Balcony</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="garage" checked/>
              <label className="form-check-label fs-sm" for="garage">Garage</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="gym"/>
              <label className="form-check-label fs-sm" for="gym">Gym</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="parking"/>
              <label className="form-check-label fs-sm" for="parking">Parking</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="pool"/>
              <label className="form-check-label fs-sm" for="pool">Pool</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="camera"/>
              <label className="form-check-label fs-sm" for="camera">Security cameras</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="wifi" checked/>
              <label className="form-check-label fs-sm" for="wifi">WiFi</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="laundry"/>
              <label className="form-check-label fs-sm" for="laundry">Laundry</label>
            </div>
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="dishwasher"/>
              <label className="form-check-label fs-sm" for="dishwasher">Dishwasher</label>
            </div>
          </div>
        </div>
        <div className="pb-4 mb-2">
          <h3 className="h6">Pets</h3>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="allow-cats"/>
            <label className="form-check-label fs-sm" for="allow-cats">Cats allowed</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="allow-dogs"/>
            <label className="form-check-label fs-sm" for="allow-dogs">Dogs allowed</label>
          </div>
        </div>
        <div className="pb-4 mb-2">
          <h3 className="h6">Additional options</h3>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="verified"/>
            <label className="form-check-label fs-sm" for="verified">Verified</label>
          </div>
          <div className="form-check">
            <input className="form-check-input" type="checkbox" id="featured"/>
            <label className="form-check-label fs-sm" for="featured">Featured</label>
          </div>
        </div>
        <div className="border-top py-4">
          <button className="btn btn-outline-primary" type="button"><i className="fi-rotate-right me-2"></i>Reset filters</button>
        </div>
      </div>
    </div>
  </aside>
  )
}

export default SidebarAccomodation