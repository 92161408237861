import { apiSlice } from "../../app/api/apiSlice";


export const propertyApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        initiateTransaction:builder.mutation({
            query:(body)=>({
                url:'transaction/initiate-payment/',
                method: 'POST',
                body:{...body}
                
            })
        }),     

        getPayoutList:builder.mutation({
            query:()=>({
                url:'transaction/receipts/',
                method: 'GET',
            
                
            })
        }),   
        getHostTransaction:builder.mutation({
            query:(params)=>({
                url:'transaction/host/',
                method: 'GET',
                params:params,
            
                
            })
        }),   
        
        withdrawalHost:builder.mutation({
            query:(body)=>({
                url:'transaction/withdraw/',
                method: 'POST',
                body:{...body}
                
            })
        }),     
 
        transactionHostCount:builder.mutation({
            query:()=>({
                url:'transaction/transaction-host-count/',
                method: 'GET',
                
                
            })
        }),    
 
        
    



    })


})
  



export const {

useInitiateTransactionMutation,
useGetPayoutListMutation,
useWithdrawalHostMutation,
useTransactionHostCountMutation,
useGetHostTransactionMutation
}=propertyApiSlice;




















