import { createSlice } from "@reduxjs/toolkit";



const propertySlice = createSlice({
    name:"property",
    initialState:{
        wishlist:null,
      
    
    },
    reducers:{
        setWishlist:(state,action)=>{
            const {wishlist}=action.payload;

         
            state.wishlist=wishlist;
           
            

        },
    
    }
})




export default propertySlice.reducer 
export const {setWishlist}=propertySlice.actions
export const selectCurrentWishlist=(state)=>state.auth.wishlist
