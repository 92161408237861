import React, { useState,useRef,useEffect } from 'react'
import { useDeletePropertyMutation } from '../../features/property/propertySlice';
import { Link,useNavigate } from 'react-router-dom';
function MyPropertyItem({property}) {
    const [showdropdown,setShowDropdown]=useState(false);
    const [showmodal,setShowModal]=useState(false);
    const dropdownRef = useRef(null);
    const modalRef = useRef(null);
    const [deleteproperty,{isLoading}]=useDeletePropertyMutation()
    const navigate=useNavigate()

    const handleNavigate=()=>{
      
      navigate(`/my-properties/${property.id}`);
    }
    const handleEditNavigate=()=>{
  
      navigate(`/my-properties/edit/${property.id}`);
    }
const handleDeleteProperty= async ()=>{
  try{

  
  const response= await deleteproperty(property.id)
  window.location.reload();
  }
  catch(e){
    console.log(e);
  }

}

    useEffect(() => {
      function handleClickOutside(event) {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
              setShowDropdown(false);
          }

          if (modalRef.current && !modalRef.current.contains(event.target)) {
            setShowModal(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside);
      return () => {
          document.removeEventListener('mousedown', handleClickOutside);
      };

  }, []); 







  return (
<>
    
  <div className={`backdrop  ${showmodal?"show":""} `}>
      <div  class="modal fade show open-modal" id="modalDefault" tabindex="-1" style={{display: 'block'}} aria-modal="true" role="dialog">
            <div ref={modalRef} class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title">Are you sure?</h4>
                  <button  onClick={()=>{setShowModal(!showmodal)}} class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <p>Do you want to delete this property</p>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-secondary btn-sm" type="button" data-bs-dismiss="modal "  onClick={()=>{setShowModal(!showmodal)}}>Cancel</button>

                  {
                    isLoading?
                    <button  class="btn btn-danger btn-shadow btn-sm" type="button">
                    <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>Deleting...
                      </button>

                    :
                    <button onClick={handleDeleteProperty} class="btn btn-danger btn-shadow btn-sm" type="button">Delete</button>

                  }
                </div>
              </div>
            </div>
          </div>
          </div>

    <div className="card card-hover card-horizontal border-0 shadow-sm mb-4">

    <div onClick={handleNavigate} className="card-img-top" style={{backgroundImage: `url(${property.files})`}}>
    <div class="position-absolute start-0 top-0 pt-3 ps-3">
    {property.status==="pending"? <span class="d-table badge bg-info">Pending</span>: <span class="d-table badge bg-success mb-1">Approved</span> } </div>
    
   
   
    </div>
  <div className="card-body position-relative pb-3">
    <div className="dropdown position-absolute zindex-5 top-0 end-0 mt-3 me-3">
      <button onClick={()=>{setShowDropdown(!showdropdown)}} className="btn btn-icon btn-light btn-xs rounded-circle shadow-sm" type="button" id="contextMenu1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fi-dots-vertical"></i></button>
      <ul ref={dropdownRef} className={`dropdown-menu my-1 ${showdropdown?"show":""}`} aria-labelledby="contextMenu1" style={{right:0}}>
      <li>
          <button onClick={handleNavigate} className="dropdown-item" type="button"><i className="fi-eye-on opacity-60 me-2"></i>View</button>
        </li>
        <li>
          <button onClick={handleEditNavigate} className="dropdown-item" type="button"><i className="fi-edit opacity-60 me-2"></i>Edit</button>
        </li>
       
  
        <li>
          <button onClick={()=>{setShowModal(!showmodal)}} className="dropdown-item" type="button"><i className="fi-trash opacity-60 me-2"></i>Delete</button>
        </li>
      </ul>
    </div>
    
    <h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" >{property.title} </a></h3>
    <p className="mb-2 fs-sm text-muted">{property.city},{property.region}</p>
    <div className="fw-bold"><i className="fi-cash mt-n1 me-2 lead align-middle opacity-70"></i>{property.pricing[0]? property.pricing[0].currency:""} {property.pricing[0]?property.pricing[0].price:""} per day</div>
    <div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap"><span className="d-inline-block me-4 fs-sm">{property.bedroom}<i className="fi-bed ms-1 mt-n1 fs-lg text-muted"></i></span><span className="d-inline-block me-4 fs-sm">{property.bathroom}<i className="fi-bath ms-1 mt-n1 fs-lg text-muted"></i></span><span className="d-inline-block fs-sm">{property.parking_spot}<i className="fi-car ms-1 mt-n1 fs-lg text-muted"></i></span></div>
  </div>

</div>
</>
  )
}

export default MyPropertyItem