import { apiSlice } from "../../app/api/apiSlice";


export const eventApiSlice=apiSlice.injectEndpoints({


    endpoints:builder=>({
        getFAQ:builder.mutation({
            query:()=>({
                url:'/admin/enquiry/faq/',
                method: 'GET',
                
            })
        }), 

    })

   

})


export const {

    useGetFAQMutation
   
}=eventApiSlice