import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../asserts/css/carousel.css";
import { Link, useNavigate } from 'react-router-dom';
import { useGetEventMutation } from '../../features/property/eventSlice'; 
import Skeleton from 'react-loading-skeleton';

function InterestingPlaces() {
  const [getEvents, { isLoading }] = useGetEventMutation();
  const [events, setEvents] = useState([]);
  const slider = useRef();
  const navigate = useNavigate();

  const handleGetEvents = async () => {
    const response = await getEvents();
    if (response.data && response.data.results) {
      setEvents(response.data.results.slice(0, 3))
      console.log("test",response.data.results.slice(0, 3)); // Adjust slice if needed
    }
  };

  const handleNavigate = (id, slug) => {
    localStorage.setItem('event_id', id);
    navigate(`/experiences/detail/${slug}`);
  };

  useEffect(() => {
    handleGetEvents();
  }, []);

  const settings = {
    dots: true,
    infinite: events.length > 1, // Only enable infinite scrolling if there is more than one event
    speed: 500,
    slidesToShow: 1, // Show the number of events available or max 3
    slidesToScroll: 1,
    swipe: true,
    touchMove: true,
    centerMode: events.length > 1, // Enable center mode only if more than one event
    centerPadding: events.length > 1 ? "150px" : "0px", // Adjust padding if only one event
    responsive: [
      {
        breakpoint: 767.98, // At max-width of 768px
        settings: {
          dots: true,
          infinite: events.length > 1,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          swipe: true,
          touchMove: true,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <section className="container mb-5 pb-lg-3">
      <div className="d-flex align-items-center justify-content-between mb-4 pb-md-2">
        <h2 className="h3 w-100 mb-md-0">Experiences</h2>
      </div>

      {isLoading ? (
        <Skeleton style={{ width: "100%", height: "300px" }} />
      ) : (
        <Slider className="slider2" {...settings} ref={slider}>
       {
            events.map((event) => (
              <div key={event.id}>
                <div
                  style={{
                    backgroundImage: `url(${event.featured_image})`,
                    backgroundSize: 'cover',
                    marginLeft: "20px",
                    height: "358px",
                  }}
                  className="card bg-size-cover bg-position-center border-0 overflow-hidden"
                >
                  <span className="img-gradient-overlay"></span>
                  <div className="card-body content-overlay pb-0">
                    <span className={`badge ${event.event_type === 'active' ? "bg-primary" : "bg-danger"} fs-sm`}>
                      {event.event_type}
                    </span>
                  </div>
                  <div className="card-footer content-overlay border-0 pt-0 pb-4">
                    <div className="d-sm-flex justify-content-between align-items-end pt-5 mt-2 mt-sm-5">
                      <Link
                        to={`/experiences/detail/${event.id}`}
                        className="text-decoration-none text-light pe-2"
                      >
                        <h3 className="h5 text-light mb-1">{event.title}</h3>
                        <div className="fs-sm opacity-70">
                          <i className="fi-map-pin me-1"></i>
                          {event.location}
                        </div>
                      </Link>
                      <div className="btn-group ms-n2 ms-sm-0 mt-3">
                        <span className="btn btn-primary px-3">₵{event.price}</span>
                        <button
                          type="button"
                          className="btn btn-primary btn-icon border-end-0 border-top-0 border-bottom-0 border-light fs-sm"
                        >
                          <i className="fi-heart"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
         
       }
        </Slider>
      )}

      <div className="d-flex justify-content-end">
        <Link to="/experiences" className="btn btn-link fw-normal ms-md-3 pb-0">
          View all
          <i className="fi-arrow-long-right ms-2"></i>
        </Link>
      </div>
    </section>
  );
}

export default InterestingPlaces;
