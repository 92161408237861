import React, { useRef, useState,useEffect } from 'react'
import logo from "../../../assets/images/site_logo.png"
import  avatar from "../../../assets/images/user/1.jpg"
import { Link } from 'react-router-dom'
function Navbar() {

const [searchdiv,setSearchDiv]=useState(false)
const [profilediv,setProfileDiv]=useState(false)
const searchdivRef=useRef(null)
const profiledivRef=useRef(null)




const handleClickOutsideSearch = (event) => {
   if (searchdivRef.current && !searchdivRef.current.contains(event.target)) {
     setSearchDiv(false);
   }
 };

 useEffect(() => {
   document.addEventListener('mousedown', handleClickOutsideSearch);

   return () => {
     document.removeEventListener('mousedown', handleClickOutsideSearch);
   };
 }, []);




 const handleClickOutsideProfile = (event) => {
   if (profiledivRef.current && !profiledivRef.current.contains(event.target)) {
     setProfileDiv(false);
   }
 };

 useEffect(() => {
   document.addEventListener('mousedown', handleClickOutsideProfile);

   return () => {
     document.removeEventListener('mousedown', handleClickOutsideProfile);
   };
 }, []);



  return (
    
    <div className="iq-top-navbar">

    
            <div className="iq-navbar-custom">
            
           
               <div className="iq-sidebar-logo  " style={{marginLeft:"50px",position:"absolute"}} >
                  <div className="top-logo">
                     <Link to="/partner" className="logo">
                     <img src={logo}  alt=""/>
                     </Link>
                  </div>
               </div>
            


            

              
               <nav className="navbar navbar-expand-lg navbar-light p-0 ">
                  
                 
                  <div className="collapse navbar-collapse" id="navbarSupportedContent">
                  
                     <ul className="navbar-nav ml-auto navbar-list">
                    
                     
                       
                   
                        <li className="nav-item iq-full-screen"><a href="#" className="iq-waves-effect" id="btnFullscreen"><i className="ri-fullscreen-line"></i></a></li>
                     </ul>
                  </div>
                  <ul className="navbar-list">
                     <li className={`${profilediv?"iq-show":""}`}>
                        <a onClick={()=>{setProfileDiv(true)}} href="#" className="search-toggle iq-waves-effect bg-primary text-white"><img src={avatar} className="img-fluid rounded" alt="user"/></a>
                        <div ref={profiledivRef} className="iq-sub-dropdown iq-user-dropdown">
                           <div className="iq-card iq-card-block iq-card-stretch iq-card-height shadow-none m-0">
                              <div className="iq-card-body p-0 ">
                                 <div className="bg-primary p-3">
                                    <h5 className="mb-0 text-white line-height">Super Admin</h5>
                                    <span className="text-white font-size-12">Available</span>
                                 </div>
                         
                               
                             
                                 <div className="d-inline-block w-100 text-center p-3">
                                    <a className="iq-bg-danger iq-sign-btn"   role="button">Sign out<i className="ri-login-box-line ml-2"></i></a>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </li>
                  </ul>
               </nav>
            </div>
            
             
         </div>
  )
}

export default Navbar