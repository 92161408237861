import React, { useEffect, useState } from 'react';
import {
  setKey,
  setLanguage,
  fromLatLng,
} from "react-geocode";
import { useDispatch } from 'react-redux';
import { setCity } from '../features/auth/authSlice';
// Set your Google API key and language here
setKey("AIzaSyADkWwYDwUmTWehisZ_iv5V09S_xEw4yWg");
setLanguage("en"); // Optional: Set the language for the address results

function GetLocation() {
  const [location, setLocation] = useState({ lat: null, lon: null });
  const [cityname, setCityName] = useState('');
  const [region, setRegion] = useState('');
  const [address, setAddress] = useState('');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();

  // Function to get the location
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          setLocation({ lat, lon });

          fromLatLng(lat, lon).then(
            (response) => {
              const cityName = response.results[0].address_components.find(component => component.types.includes("locality"))?.long_name || 'N/A';

              setCityName(cityName);
              dispatch(setCity({city:cityName}))
            },
            (err) => {
              setError("Error fetching address: " + err.message);
              console.error(err);
            }
          );
        },
        (err) => {
          setError("Geolocation error: " + err.message);
        }
      );
    } else {
      setError('Geolocation is not supported by this browser.');
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  return (
    <>
  
    </>
  );
}

export default GetLocation;
