
import React ,{useEffect}from 'react';
import Sidebar from './profilecomponents/sidebar';
import Layout from '../pages/Layout';
import { Outlet ,useLocation} from 'react-router-dom';
import { Link } from'react-router-dom';

const DashboardLayout = () => {
    const location = useLocation();
    const path = location.pathname;

   

  
    // Determine the breadcrumb based on the current path
    let breadcrumb = '';
    if (path.includes('/dashboard/wishlist')) {
      breadcrumb = 'Wishlist';
    } else if (path.includes('/dashboard/password-security')) {
      breadcrumb = 'Password Security';
    } else if (path.includes('/dashboard/personal-info')) {
      breadcrumb = 'Personal Info';
    } else if (path.includes('/dashboard/recent-booking')) {
      breadcrumb = 'Recent Booking';
    } else if (path.includes('/dashboard/my-properties/approvals')) {
      breadcrumb = 'Pending Booking';
    } else if (path.includes('/dashboard/my-properties')) {
      breadcrumb = 'My Properties';
    } else if (path.includes('/dashboard/payment-history')) {
      breadcrumb = 'Payment History';
    } else if (path.includes('/dashboard/refund-request')) {
      breadcrumb = 'Refund Request';
    } else if (path.includes('/dashboard/reviews')) {
      breadcrumb = 'Reviews';
    } else if (path.includes('/dashboard/notifications')) {
      breadcrumb = 'Notifications';
    } else if (path.includes('/dashboard/my-properties/:id')) {
      breadcrumb = 'Property Detail';
    } else if (path.includes('/dashboard/my-properties/edit/:id')) {
      breadcrumb = 'Edit Property';
    } else if (path.includes('/dashboard/my-properties/add')) {
      breadcrumb = 'Add Property';
    }
  
  return (
    <Layout>
      <div className="container pt-5 mt-5">
      <nav className="mb-4 pt-md-3" aria-label="Breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Home</Link></li>
            <li className="breadcrumb-item"><span>Account</span></li>
            <li className="breadcrumb-item active" aria-current="page">{breadcrumb}</li>
          </ol>
        </nav>
        <div className='row'>
        <Sidebar />

          <Outlet/>
          </div>
        
      </div>
    </Layout>
  );
};

export default DashboardLayout;
