import React, { useState ,useRef} from 'react'
import signinlogo from "../asserts/img/signin-modal/signin.svg"
import { useDispatch } from 'react-redux';
import { setCredentials } from '../features/auth/authSlice';
import { Link, useNavigate } from 'react-router-dom';
import { useLoginMutation,useLogiWithGoogleMutation } from '../features/auth/authApiSlice';
import { useGoogleLogin} from '@react-oauth/google';
import "../asserts/css/theme.min.css"


function SigninPage() {
const  [seepassword,setseepassword]=useState(false)
const navigate = useNavigate();
const formref=useRef(null)
const[login,{isLoading}]=useLoginMutation()
const dispatch = useDispatch()
const[incorrectdetails,setIncorectDetails]=useState(false)
const [invalidrole,setInvalidRole]=useState()
const [loginwithGoogle,{isLoading:isLoadingGoogle}]=useLogiWithGoogleMutation()

const handleAlertClose=()=>{
  setIncorectDetails(false)
}

const [formData, setFormData] = useState({
 
  email: "",
  'password':"",
  
  

});

 // Function to go back to the previous page
 const goBack = () => {
   navigate(-1);
 

 }

 const handleChange = (event) => {
  const { name, value } = event.target;
  setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
};



const handleLogin=async (e)=>{
  
e.preventDefault()
    const isValid = formref.current.checkValidity(); // Check if all form elements are valid

	console.log(isValid);
	
	

    if (isValid) {

      try {



        const signinData= await login({"email":formData.email,'password':formData.password}).unwrap()
        console.log(signinData)
        if (signinData.role ==="partner"){

          setInvalidRole(` You can't login to your ${signinData.role} account.`)
          setIncorectDetails()
          
        }else{
        if (signinData.is_email_verified===false){
          dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_email_verified:signinData.is_email_verified}))

          navigate("/verify")
        }
        else{
          dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_email_verified:signinData.is_email_verified}))
          navigate("/")
        }
      }
      
      formData.email=""
      formData.password=""
      }
        
          catch(e){
            console.log(e)
            if ( e.status===401){
setIncorectDetails(true)
            }
          }
      
    }
    else{

      formref.current.classList.add("was-validated")


    }

}



const loginUsingGoogle = useGoogleLogin({
  onSuccess: async(codeResponse) => {

    const signinData = await loginwithGoogle({ access_token: codeResponse.access_token }).unwrap();
    if (signinData.role ==="partner"){

      setInvalidRole(` You can't login to your ${signinData.role} account.`)
      setIncorectDetails()
      
    }
    else{
    if (signinData.is_email_verified===false){
      dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_email_verified:signinData.is_email_verified}))

      navigate("/verify")
    }
    else{
      dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_email_verified:signinData.is_email_verified}))
      navigate("/")
    }
  }



    
  },
  onError: (error) => console.log('Login Failed:', error)
});



  return (
    <div className=" fade show fade py-5" style={{diaplay:"block",backgroundColor:"#f0f0f0"}} id="signup-modal" tabindex="-1" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered p-2 my-0 mx-auto" style={{maxWidth: "950px"}}>
      <div className="modal-content">
        <div className="modal-body px-0 py-2 py-sm-0">
        <buton  onClick={goBack} style={{cursor:"pointer"}} class=" position-absolute top-0 end-0 nav-link fs-sm py-1 px-2 mt-3 me-3" href="#" onclick="window.history.go(-1); return false;">
				<i class="fi-arrow-long-left fs-base me-2"></i>
				Go back
			</buton>          <div className="row mx-0 align-items-center">
            <div className="col-md-6 border-end-md p-4 p-sm-5">
              <h2 className="h3 mb-4 mb-sm-5">
              Hey there!<br/> Welcome back.
              </h2>
              <ul className="list-unstyled mb-4 mb-sm-5">
                <li className="d-flex mb-2"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Add and promote your property</span></li>
                <li className="d-flex mb-2"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Easily manage your wishlist</span></li>
                <li className="d-flex mb-0"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Leave reviews</span></li>
              </ul><img className="d-block mx-auto" src={signinlogo} width="344" alt="Illustartion"/>
              <div className="mt-sm-4 pt-md-3">Don't have an account? <Link to='/register' data-bs-toggle="modal" data-bs-dismiss="modal">Sign up</Link></div>
            </div>
            <div className="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
              
              <form onSubmit={handleLogin} className="needs-validation" ref={formref} novalidate>

              <button  type='button' onClick={loginUsingGoogle} class="btn btn-outline-info rounded-pill  w-100 mb-3  " href="#"> 
              <svg aria-hidden="true" class="native svg-icon iconGoogle mb-1" width="18" height="18" viewBox="0 0 18 18"><path fill="#4285F4" d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18"></path><path fill="#34A853" d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17"></path><path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18z"></path><path fill="#EA4335" d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.8 4.8 0 0 1 4.48-3.3"></path></svg>
               <span className="mx-1">Sign in with Google</span> </button>
{
  incorrectdetails?             <div class="alert alert-danger alert-dismissible fade show" role="alert">
  <span class="fw-bold">Error</span> Invalid username or password!
  <button type="button" class="btn-close" onClick={handleAlertClose} ></button>
</div>:""
}
  


                <div className="mb-4">
                  <label className="form-label" for="signup-email">Email address</label>
                  <input  name="email"   onChange={handleChange}  value={formData.email} className="form-control" type="email" id="signin-email" placeholder="Enter your email" required/>
                </div>


                <div className="mb-4">
                <div class="d-flex align-items-center justify-content-between mb-2">
								<label class="form-label mb-0" for="signin-password">Password</label>
								<a class="fs-sm" >
									Forgot password?
								</a>
							</div>                  <div className="password-toggle">
                    <input  name="password"   onChange={handleChange}  value={formData.password}  className="form-control" type={seepassword ? "text":"password"} id="signup-password" minlength="8" required/>
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input className="password-toggle-check" type="checkbox"/>
                      <span onClick={()=>{ setseepassword(!seepassword)}}   className="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>

{
  isLoading?<button type="button" class="btn btn-primary btn-lg rounded-m w-100 ">
  <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
  Login in ...
  
</button>: <button className="btn btn-primary btn-lg rounded-m w-100 " type="submit">Sign in</button>

}

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  )
}

export default SigninPage