import { apiSlice } from "../../app/api/apiSlice";
import { apiSlice1 } from "../../app/api/apiSlice1";

export const authApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        login:builder.mutation({
            query:credentials=>({
                url:'/auth/login/',
                method: 'POST',
                body:{...credentials}
            })
        }),
        register:builder.mutation({
            query:body=>({
                url:'/auth/register/',
                method: 'POST',
                body:{...body}
            })
        }),
        verifynumber:builder.mutation({
            query:body=>({
                url:'/auth/verify-email/',
                method: 'POST',
                body:{...body}
            })
        }),
        resendotp:builder.mutation({
            query:body=>({
                url:'/auth/resend-otp/',
                method: 'POST',
                body:{...body}
            })
        }),

        getprofile:builder.mutation({
            query:body=>({
                url:'/auth/profile/',
                method: 'GET',
                
            })
        }),

        updateprofile:builder.mutation({
            query:body=>({
                url:'/auth/profile/',
                method: 'PATCH',
                body:body

                
            }),
            
        

        
    }),

    changeToHost:builder.mutation({
        query:body=>({
            url:'/client/change-to-host/',
            method: 'POST',
            body:{...body}

            
        }),
        
    

    
}),
    changepassword:builder.mutation({
        query:body=>({
            url:'/auth/password_change/',
            method: 'PUT',
            body:{...body}

            
        })
}),




logiWithGoogle:builder.mutation({
    query:body=>({
        url:'/auth/login/google/',
        method: 'POST',
        body:{...body}

        
    })
})



})

})
  

export const authApiSlice1=apiSlice.injectEndpoints({
    endpoints:builder=>({
    updateprofile1:builder.mutation({
        query:(body)=>({
            url:'/auth/profile/',
            method: 'PATCH',
            body:body
            
        })
    })
}),

})

export const {
    useLoginMutation,
    useRegisterMutation,
    useVerifynumberMutation,
    useResendotpMutation,
    useGetprofileMutation,
    useUpdateprofileMutation,
    useChangepasswordMutation,
    useChangeToHostMutation,
    useLogiWithGoogleMutation,
  

}=authApiSlice;


export  const {
useUpdateprofile1Mutation
}=authApiSlice1
