
import Skeleton from 'react-loading-skeleton';



function HeroSkeleton() {


 

  return (
    <section className="container py-5 mt-5 mb-lg-3">
    <div className="row align-items-center mt-md-2">
      <div className="col-lg-7 order-lg-2 mb-lg-0 mb-4 pb-2 pb-lg-0">
      <Skeleton height={{width:"746px",height:"600px"}}/>
    </div>
   
      <div className="col-lg-5 order-lg-1 pe-lg-0">

<Skeleton style={{height:"200px"}} className='mt-5'/>
       
      </div>
      <div className="col-xl-8 col-lg-10 order-3 mt-lg-n5">
          
            <Skeleton className="form-group  d-block mx-2"  style={{height:"150px"}}/>
          </div>
    </div>
  </section>
  )
}

export default HeroSkeleton