import React from 'react'
import Footer from '../components/footer';
import Navbar from '../components/navbar';
import { useSelector } from 'react-redux';
import { selectCurrentToken } from '../features/auth/authSlice';
import { Outlet } from 'react-router-dom';

function Layout({children}) {
  const token=useSelector(selectCurrentToken)

  return (
    <div>
    <Navbar token={token} />
    <div className="">
    <div className='row'>

    </div>
    {children}
    </div>
    
    <Footer />
  </div>
  )
}

export default Layout