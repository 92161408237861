import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUserData } from '../../features/auth/authSlice';
import { useWithdrawalHostMutation } from '../../features/transaction/transactionSlice';
import "../../asserts/css/theme.min.css";


function PayoutForm() {
    const userdata = useSelector(selectUserData);
    const[withdraw,{isLoading}]=useWithdrawalHostMutation()
    const [errorMessage,setErrorMessage]=useState("");
    const [successMessage,setSuccessMessage]=useState("");
    const formRef=useRef(null)

    const [Withdrawaldata, setWithdrawalData] = useState({
        amount: "",
        code: ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setWithdrawalData({
            ...Withdrawaldata,
            [name]: value
        });
    };


    const handleWithdrawal=async(e)=>{
        e.preventDefault()
        const isValid = formRef.current.checkValidity(); // Check if all form elements are valid
  

        if (isValid){

            try{

                const response= await withdraw(Withdrawaldata).unwrap();
                console.log(response) 
                setSuccessMessage("withdrawal successfully")

            }catch(e){
                console.log(e)
                setErrorMessage(e.data.detail)
            }
        }
        else{
            formRef.current.classList.add("was-validated")


        }
         
    }

    return (
        <div className='container mt-5 mb-md-4 py-5'>
            <div className="col-lg-8">
                <nav className="mb-3 pt-md-3" aria-label="Breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to='/'>Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to="payment-history">Payouts</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">Request Payout</li>
                    </ol>
                </nav>

                <div className="mb-4">
                    <h1 className="h2 mb-0">Payout Form</h1>
                </div>

                {
                    errorMessage.length>0?
                    <div class="alert alert-danger  alert-dismissible fade show" role="alert">
  <span class="fw-bold"></span> {errorMessage}
  <button  onClick={()=>{setErrorMessage("")}} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
                    :
                    ""

                }


                {
                    successMessage.length>0?
                    <div class="alert alert-success  alert-dismissible fade show" role="alert">
  <span class="fw-bold"></span> {successMessage}
  <button  onClick={()=>{setWithdrawalData("")}} type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
</div>
                    :
                    ""

                }
                <form  ref={formRef} onSubmit={handleWithdrawal} className='needs-validation' noValidate>
                    <section className="card card-body border-0 shadow-sm p-4 mb-4" id="basic-info">
                        <h2 className="h4 mb-4">
                            <i className="fi-info-circle text-primary fs-5 mt-n1 me-2"></i>
                            Account Details
                        </h2>
                        <div className="mb-3">
                            <label className="form-label" htmlFor="ap-account_type">Account Type <span className="text-danger">*</span></label>
                            <select required className="form-select" id="ap-account_type" name="account_type" onChange={handleChange} value={Withdrawaldata.account_type || ''}>
                                <option value="" selected disabled hidden>Choose Account Type</option>
                                <option value="mobile_money">Mobile Money</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label className="form-label" htmlFor="ap-account_number">Account Number <span className="text-danger">*</span></label>
                            <input required className="form-control text-muted" disabled type="text" id="ap-account_number" value={userdata && userdata.phone_number} placeholder="Enter MOMO Number, Account Number or Card Number." name="account_number" />
                        </div>

                        <div className="mb-3">
                            <label className="form-label" htmlFor="ap-account_number">Network <span className="text-danger">*</span></label>
                            <select required className="form-select" id="ap-account_type" name="code" onChange={handleChange} value={Withdrawaldata.code || ''}>
                                <option value="" selected disabled hidden>Choose Account Type</option>
                                <option value="mtn">Mtn</option>
                                <option value="vod">Telecel</option>
                                <option value="atl">AirtelTigo</option>
                            </select>
                        </div>
                    </section>

                    <section className="card card-body border-0 shadow-sm p-4 mb-4" id="price">
                        <h2 className="h4 mb-4">
                            <i className="fi-cash text-primary fs-5 mt-n1 me-2"></i>
                            Amount To Withdraw
                        </h2>
                        <label className="form-label" htmlFor="ap-price">Amount <span className="text-danger">*</span></label>
                        <div className="input-group">
                            <span className="input-group-text text-muted">₵</span>
                            <input className="form-control" required type="number" id="ap-price" min="0" name="amount" placeholder="Enter amount with extra two zeros. e.g. 1000 = 10" onChange={handleChange} value={Withdrawaldata.amount} />
                        </div>
                    </section>

                    <section className="d-sm-flex justify-content-between pt-2">
                        <button  className="btn btn-primary btn-lg mb-2" type="submit">Submit Request</button>
                    </section>
                </form>
            </div>
        </div>
    );
}

export default PayoutForm;
