import React, { useEffect, useState } from 'react'
import { Link, useNavigate,} from 'react-router-dom'
import Sidebar from '../../components/profilecomponents/sidebar'
import { useGetWishlistMutation , useAddToWishlistMutation } from '../../features/property/propertySlice'
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "../../asserts/css/theme.min.css"




function Wishlist() {
const [getwishlist,{isLoading}]=useGetWishlistMutation()
const [wishlist,setWishlist]=useState()
const [iswishlist,setIsWishlist]=useState()
const navigate=useNavigate()
const [addToWishlist] = useAddToWishlistMutation();

const handlegetwishlist= async ()=>{
	try{
		const response = await getwishlist();
		console.log("response",response)
		setWishlist(response.data)
		
		

	}
	catch(e){
		console.log(e)
	}
	




}


const handleNavigate = (id) => {
	localStorage.setItem("property_id", id);
	navigate(`/properties/detail/${id}`);
};


useEffect(()=>{
	handlegetwishlist();
},[])

const handleAddToWishlist = async (property_id) => {
	try {

		if (!iswishlist) {
		const response = await addToWishlist({ property_id: property_id }).unwrap();
		console.log(response);
		setIsWishlist(true); // Update state to re-render the component
		handlegetwishlist()
		}

		else{
			setIsWishlist(false); // Update state to
		}
	}
	catch (e) {
		console.log(e);
	}
};

  return (

  <div className="col-lg-8 col-md-7 mb-5">
				<div >

	<div className="d-flex align-items-center justify-content-between mb-4 pb-2">
		<h1 className="h2 mb-0">Wishlist</h1>
		{
			wishlist&&wishlist.length>0 ?
			<a className="fw-bold text-decoration-none" href="#!">
				<i className="fi-x fs-xs mt-n1 me-2"></i>
				Clear all
			</a>
			:
				""
		}
				
			</div>
			
			{
				

				isLoading?  <>
              <div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>


<div className="card card-hover card-horizontal border-0 shadow-sm mb-4">
<div  className="card-img-top" >
<Skeleton    className="card-left position-absolute card-left start-0 top-0 pt-3 ps-3"/></div>
<div className="card-body position-relative pb-3">

<h3 className="h6 mb-2 fs-base"><a className="nav-link stretched-link" ><Skeleton style={{width:'100px',height:"20px"}}/> </a></h3>
<p className="mb-2 fs-sm text-muted"><Skeleton style={{width:'50px',height:"20px"}}/></p>
<div className="fw-bold"><Skeleton style={{width:'50px',height:"10px"}}/></div>
<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">

<Skeleton style={{width:'50px',height:"10px"}}/>
</div>
</div>

</div>
</>
:
<>


{ wishlist&& wishlist.length>0?
	wishlist &&wishlist.map((item,index)=>{
		
		return (
			<div key={index} className="card card-hover card-horizontal border-0 shadow-sm mb-4">
						<div className="card-img-top position-relative" style={{backgroundImage: `url('https://dev-api.shortstaybuddy.com${item.property.files}')`}}>
				<a className="stretched-link" ></a>
				<div className="position-absolute start-0 top-0 pt-3 ps-3">
														</div>
				<div className="position-absolute end-0 top-0 pt-3 pe-3 zindex-5">
				{
					item.property.wishlist?
					<button onClick={()=>{handleAddToWishlist(item.property.id)}} className="btn btn-icon btn-light btn-xs text-primary rounded-circle shadow-sm" type="button" data-bs-toggle="tooltip" data-bs-placement="left" title=""  data-bs-original-title="Remove from Wishlist">
						<i className="fi-heart-filled"></i>
					</button>:""
				}
					
				</div>
			</div>
			<div  onClick={()=>{handleNavigate(item.property.id)}} className="card-body position-relative pb-3">
				<h4 className="mb-1 fs-xs fw-normal text-uppercase text-primary">
					Apartments
				</h4>
				<h3 className="h6 mb-2 fs-base">
					<a className="nav-link stretched-link" >
						Two bedroom Inside Ofankor Taifa
					</a>
				</h3>
				
				<div className="fw-bold"><i className="fi-cash mt-n1 me-2 lead align-middle opacity-70"></i>
					GHS
					500.00
				</div>
				<div className="d-flex align-items-center justify-content-center justify-content-sm-start border-top pt-3 pb-2 mt-3 text-nowrap">
					<span className="d-inline-block me-4 fs-sm">
						2
						<i className="fi-bed ms-1 mt-n1 fs-lg text-muted"></i>
					</span>
					<span className="d-inline-block me-4 fs-sm">
						2
						<i className="fi-bath ms-1 mt-n1 fs-lg text-muted"></i>
					</span>
					<span className="d-inline-block fs-sm">
						3
						<i className="fi-car ms-1 mt-n1 fs-lg text-muted"></i>
					</span>
				</div>
			</div>
		</div>
			
		)
	})

	:
	<div class="card card-hover card-horizontal border-0 shadow-sm mb-4">
			<div class="card-body position-relative pb-3">
				<h3 class="h6 mb-2 fs-base">No items in your wishlist</h3>
			</div>
		</div>
}

		
</>

			}
		
	
</div>

	</div>

  )
}

export default Wishlist