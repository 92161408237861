import React,{useState,useEffect} from 'react'
import { useGetBlogDetailMutation } from '../features/property/propertySlice'
import { Link,useParams,useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../features/auth/authSlice'
import Skeleton from 'react-loading-skeleton'
function BlogDetail() {
    const [blog,setBlog]=useState()
    const [getblog,{isLoading}]=useGetBlogDetailMutation()
    const [date,setDate]=useState()
    const {id} = useParams();
    const location = useLocation();

    const handleGetBlog=async(id)=>{
        try{

            const response=await getblog(id)
            console.log(response.data)
            setBlog(response.data)
             setDate( new Date(response.data.created_at));
        }
        catch(e){
            console.log(e)
        }
        

    }

    const token =useSelector(selectCurrentToken)

    useEffect(()=>{
        handleGetBlog(id)
    },[])
    const currentUrl = window.location.origin + location.pathname;


    const shareLinks = [
        {
          href: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`,
          iconClass: 'fi-facebook fs-base opacity-75 me-2',
          text: 'Facebook',
        },
        {
          href: `https://twitter.com/intent/tweet?text=Check%20this%20out!&url=${encodeURIComponent(currentUrl)}`,
          iconClass: 'fi-twitter fs-base opacity-75 me-2',
          text: 'Twitter',
        },
        {
          href: `https://wa.me/?text=${encodeURIComponent(currentUrl)}`,
          iconClass: 'fi-whatsapp fs-base opacity-75 me-2',
          text: 'WhatsApp',
        },
        {
          href: `https://telegram.me/share/url?url=${encodeURIComponent(currentUrl)}&text=Check%20this%20out!`,
          iconClass: 'fi-telegram fs-base opacity-75 me-2',
          text: 'Telegram',
        },
      ];
  return (
    <div class="container mt-5 mb-md-4 py-5">
    
    <nav class="mb-3 pt-md-3" aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><Link to="/" >Home</Link></li>
				<li class="breadcrumb-item"><Link to ="/posts" >Our Blog</Link></li>
				<li class="breadcrumb-item active" aria-current="page">
                {blog && blog.title }
				</li>
			</ol>
		</nav>


        <a class="nav-link d-inline-block fw-normal text-uppercase px-0 mb-2" href="#">
			{blog && blog.category.name}
		</a>

        <h1 class="h2 mb-4">{blog && blog.title }</h1>

        <div class="mb-4 pb-1">
			<ul class="list-unstyled d-flex flex-wrap mb-0 text-nowrap">
				<li class="me-3">
					<i class="fi-calendar-alt me-2 mt-n1 opacity-60"></i>
					
					{
                     
                            date&& date.toDateString()
                    }
				</li>
			
			</ul>
		</div>


        <div class="mb-4 pb-md-3">
        {
          isLoading?
          <Skeleton style={{height:"300px"}}/>
          :
          <img class="rounded-3" style={{height:"600px",objectFit:"cover" ,width: "100%"}} src={`https://api.shortstaybuddy.com${blog&&blog.image}`} alt="Post image" />

        }
		</div>
    



        <div>

<div class="row">
    <div class="col-lg-2 col-md-1 mb-md-0 mb-4 mt-md-n5">
        
        <div class="sticky-top py-md-5 mt-md-5">
            <div class="d-flex flex-md-column align-items-center my-2 mt-md-4 pt-md-5">
                <div class="d-md-none fw-bold text-nowrap me-2 pe-1">Share:</div>
                
                {shareLinks.map((link, index) => (
        <a key={index} href={link.href} className="btn btn-icon btn-light-primary btn-xs shadow-sm rounded-circle mb-md-2 me-md-0 me-2 d-flex " target="_blank" rel="noopener noreferrer">
          <i className={link.iconClass} ></i>
          
        </a>
      ))}
            </div>
        </div>
    </div>
    <div class="col-lg-8 col-md-10">
     
        <div class="mb-4 pb-md-3">
            <a class="d-flex align-items-center text-body text-decoration-none" href="#">
                <img class="rounded-circle" src={blog&&blog.author.profile} width="80" alt="Kristin Watson"/>
                <div class="ps-3">
                {
                  isLoading?
                  <Skeleton circle={true} style={{width:"80px",height:"80px"}}/>
                  :
                  <h2 class="h6 mb-1">{blog&& blog.author.first_name} {blog&& blog.author.last_name}</h2>

                }
                    <span class="fs-sm"></span>
                </div>
            </a>
        </div>
    
<p> 
{
  isLoading?
  <Skeleton style={{height:"00px"}}/>
  :
  <div dangerouslySetInnerHTML={{ __html: blog&&blog.body }}>
</div>

}

</p>
        

                     
            <div class="d-flex align-items-center my-md-5 my-4 py-md-4 py-3 border-top">
                <div class="fw-bold text-nowrap mb-2 me-2 pe-1">Tags:</div>
                <div class="d-flex flex-wrap">

                {blog&& blog.tags.length>0?blog.tags.map((tag,index)=>{
                    return (
                        
                        <a key={index} class="btn btn-xs btn-outline-secondary rounded-pill fs-sm fw-normal me-2 mb-2" href="#!">
                           {tag.name}
                        </a>
                                

                    )
                }): ""
                      }                        </div>
            </div>
                   
        <div >

<div class="mb-4 mb-md-5" id="comments">
    <h3 class="mb-4 pb-2">0 comments</h3>

                <div class="text-center">
            <h3 class="text-muted">No comments yet</h3>
        </div>
    
    
        </div>

</div>

	</div>



    <div class="card py-md-4 py-3 shadow-sm">
        <div class="card-body col-lg-8 col-md-10 mx-auto px-md-0 px-4">
            <h3 class="mb-4 pb-sm-2">Leave your comment</h3>
            {token?
            
                <form class="needs-validation row gy-md-4 gy-3" novalidate="" >
						<div class="col-12">
							<label class="form-label" for="comment-text">Comment</label>
							<textarea class="form-control form-control-lg" id="comment-text" rows="4" placeholder="Type comment here" required="">                        </textarea>
							<div class="invalid-feedback">Please type your comment.</div>

													</div>
						<div class="col-12 py-2">
							<button class="btn btn-lg btn-primary" type="submit">Post comment</button>
						</div>
					</form>
            :           <div class="alert alert-info">
                    <p class="mb-0">You need to <Link to="/login" >login</Link> to comment on this post.</p>
                </div>}
                     
                        </div>
    </div>
</div>

</div>
    
    </div>
  )
}

export default BlogDetail