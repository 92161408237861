import React,{useState,useEffect} from 'react'
import {animateScroll as scroll,  } from 'react-scroll';

function Backtotop() {

    const [isVisible, setIsVisible] = useState(false);

    // Show button when page is scrolled down
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };
  
    useEffect(() => {
        window.addEventListener('scroll', toggleVisibility);
        return () => {
          window.removeEventListener('scroll', toggleVisibility);
        };
      }, []);

  return (
    <a onClick={()=>{scroll.scrollToTop();}} class={`btn-scroll-top  ${isVisible?"show":""}`} data-scroll=""><span class="btn-scroll-top-tooltip text-muted fs-sm me-2">Top</span><i class="btn-scroll-top-icon fi-chevron-up">   </i></a>
  )
}

export default Backtotop