import React from 'react'
import { useLocation,Navigate,Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../features/auth/authSlice'

function RequireAuthPartner() {
    const token =useSelector(selectCurrentToken)
   
    const location=useLocation()
  return (
    token ?
    <Outlet/>:<Navigate to="/partner/login" state={{from:location}} replace />
  )
}

export default RequireAuthPartner