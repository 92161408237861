import React, { useState,useEffect } from 'react'

import { Link } from 'react-router-dom'
import activesupport from '../asserts/img/real-estate/illustrations/activesupport.png'


function HelpCenter() {

    const [toggleGeneral,setToggleGeneral]=useState(true)
    const [toggleBooking,setToggleBooking]=useState(false)
    const [toggleCancellation,setToggleCancelation]=useState(false)
    const [togglePayment,setTogglePayment]=useState(false)
    const [toggleOther,setToggleOther]=useState(false)
	const [searchQuery, setSearchQuery] = useState('');


	const handleToggle = (tab) => {
        setToggleGeneral(tab === 'general');
        setToggleBooking(tab === 'booking');
        setToggleCancelation(tab === 'cancellation');
        setTogglePayment(tab === 'payment');
        setToggleOther(tab === 'other');
    };


	const handleSearch = (e) => {
        e.preventDefault();
        const searchContent = searchQuery.toLowerCase();

        if (searchContent.includes('General What if I have a problem during my stay? If you have a problem during your stay, please contact our customer support team right away. We will do everything we can to resolve the issue and ensure that you have a pleasant stay. How do I leave a review? After your stay, you will receive an email inviting you to leave a review. You can also log in to your account and go to your bookings to leave a review. Your review will be posted on the property listing page and will help future guests make informed decisions about their stay.')) 
{
            handleToggle('general');
        } else if (searchContent.includes('booking')) {
            handleToggle('booking');
        } else if (searchContent.includes('cancellation')) {
            handleToggle('cancellation');
        } else if (searchContent.includes('payment')) {
            handleToggle('payment');
        } else if (searchContent.includes('other')) {
            handleToggle('other');
        }
    };


	useEffect(() => {
        const timer = setTimeout(() => {
            const searchContent = searchQuery.toLowerCase();

            if (searchContent.includes('general')) {
                handleToggle('general');
            } else if (searchContent.includes('booking')) {
                handleToggle('booking');
            } else if (searchContent.includes('cancellation')) {
                handleToggle('cancellation');
            } else if (searchContent.includes('payment')) {
                handleToggle('payment');
            } else if (searchContent.includes('other')) {
                handleToggle('other');
            }
        }, 500); // Adjust the delay as needed (500 ms in this case)

        return () => clearTimeout(timer);
    }, [searchQuery]);

	
	const handleReset=()=>{
		setSearchQuery("");
		handleToggle("general")
	}


  return (
    <div class="container pt-5 pb-lg-4 mt-5 mb-sm-2">
    <nav class="mb-4 pt-md-3" aria-label="Breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item">
					<Link to='/' >
						Home
					</Link>
				</li>
				<li class="breadcrumb-item active" aria-current="page">
					Help center
				</li>
			</ol>
		</nav>


        <div>

	<section class="container mb-4 pb-lg-5">
		<h1 class="mx-auto mb-4 pb-2 text-center" style={{maxWidth:"856px"}}>Hi, how can we help?</h1>
		
		<di class="form-group mb-4">
			<div class="input-group input-group-lg">
				<input class="form-control" 
					className="form-control"
                                type="text"
                                placeholder="What are you looking for?"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
				/>
			</div>
			<button onClick={handleReset} class="btn btn-primary btn-lg px-sm-4 px-3" >
				<i class="fi-refresh me-sm-2"></i>
				<span class="d-sm-inline d-none">
					Reset Search
				</span>
			</button>
		</di>
		
	</section>


	
	<section className="container mb-md-5 mb-4 pb-lg-5">
                    <div className="row">
                        <aside className="col-xl-2 col-lg-3">
                            <div className="offcanvas offcanvas-start offcanvas-collapse" id="help-sidebar">
                                <div className="offcanvas-header shadow-sm mb-2">
                                    <h2 className="h5 mb-0">FAQ</h2>
                                    <button className="btn-close" type="button" data-bs-dismiss="offcanvas"></button>
                                </div>
                                <div className="offcanvas-body">
                                    <ul className="nav nav-tabs navbar-nav flex-column m-0 text-lg-center">
                                        <li className="nav-item px-0" style={{ cursor: "pointer" }} onClick={() => handleToggle('general')}>
                                            <span className={`nav-link px-3 ${toggleGeneral ? "active" : ""}`}>
                                                General
                                            </span>
                                        </li>
                                        <li className="nav-item px-0" style={{ cursor: "pointer" }} onClick={() => handleToggle('booking')}>
                                            <span className={`nav-link px-3 ${toggleBooking ? "active" : ""}`}>
                                                Booking
                                            </span>
                                        </li>
                                        <li className="nav-item px-0" style={{ cursor: "pointer" }} onClick={() => handleToggle('cancellation')}>
                                            <span className={`nav-link px-3 ${toggleCancellation ? "active" : ""}`}>
                                                Cancellation
                                            </span>
                                        </li>
                                        <li className="nav-item px-0" style={{ cursor: "pointer" }} onClick={() => handleToggle('payment')}>
                                            <span className={`nav-link px-3 ${togglePayment ? "active" : ""}`}>
                                                Payment
                                            </span>
                                        </li>
                                        <li className="nav-item px-0" style={{ cursor: "pointer" }} onClick={() => handleToggle('other')}>
                                            <span className={`nav-link px-3 ${toggleOther ? "active" : ""}`}>
                                                Other
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </aside>
                        <div className='tab-content col-lg-9 offset-xl-1 col-lg-8'>
                            <div id='payment' className={`tab-pane fade ${togglePayment ? "show active" : ""}`}>
                                <h2 className="mb-md-4 mb-3 pb-md-2">Payment</h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">What payment methods do you accept?</h3>
                                    <p>
                                        We accept Mobile Money and all major credit and debit cards, including Visa, Mastercard, American Express, and Discover. Our payment system is well secured and trusted.
                                    </p>
                                </div>
                                <h2 className="mb-md-4 mb-3 pb-md-2"></h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">Are there any hidden fees?</h3>
                                    <p>
                                        No, there are no hidden fees. The price you see on the listing page is the total price you will pay for your stay, including any cleaning fees, or other charges. We believe in transparency and want to make sure our guests have a clear understanding of the cost of their stay before booking.
                                    </p>
                                </div>
                                <div></div>
                            </div>

                            <div className={`tab-pane fade ${toggleGeneral ? "show active" : ""}`}>
                                <h2 className="mb-md-4 mb-3 pb-md-2">General</h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">What if I have a problem during my stay?</h3>
                                    <p>
                                        If you have a problem during your stay, please contact our customer support team right away. We will do everything we can to resolve the issue and ensure that you have a pleasant stay.
                                    </p>
                                </div>
                                <h2 className="mb-md-4 mb-3 pb-md-2"></h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">How do I leave a review?</h3>
                                    <p>
                                        After your stay, you will receive an email inviting you to leave a review. You can also log in to your account and go to your bookings to leave a review. Your review will be posted on the property listing page and will help future guests make informed decisions about their stay.
                                    </p>
                                </div>
                                <div></div>
                            </div>

                            <div className={`tab-pane fade ${toggleBooking ? "show active" : ""}`}>
                                <h2 className="mb-md-4 mb-3 pb-md-2">Booking</h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">How do I book a stay on Accra Short Stays?</h3>
                                    <p>
                                        Booking a stay on short stay buddy is easy. Simply visit our website and enter your desired location budget. Our website will show you available properties, and you can book directly through our website.
                                    </p>
                                </div>
                                <h2 className="mb-md-4 mb-3 pb-md-2"></h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">What is included in the rental price?</h3>
                                    <p>
                                        The rental price includes the cost of the property, as well as any applicable taxes and fees. Some properties may also include additional amenities or services, such as daily housekeeping or airport transportation.
                                    </p>
                                </div>
                                <h2 className="mb-md-4 mb-3 pb-md-2"></h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">Can I request an early check-in or late check-out?</h3>
                                    <p>
                                        Early check-in and late check-out may be available upon request, but it depends on the property and availability. Please contact us in advance to see if this is possible.
                                    </p>
                                </div>
                                <div></div>
                            </div>

                            <div className={`tab-pane fade ${toggleCancellation ? "show active" : ""}`}>
                                <h2 className="mb-md-4 mb-3 pb-md-2">Cancellation</h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">What is your cancellation policy?</h3>
                                    <p>
                                        Our cancellation policy varies depending on the property and the time of year. Please refer to the property listing page for the specific cancellation policy for your booking.
                                    </p>
                                </div>
                                <h2 className="mb-md-4 mb-3 pb-md-2"></h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">Can I modify my reservation?</h3>
                                    <p>
                                        You may be able to modify your reservation, depending on the property and availability. Please contact us as soon as possible if you need to make any changes to your booking.
                                    </p>
                                </div>
                                <div></div>
                            </div>

                            <div className={`tab-pane fade ${toggleOther ? "show active" : ""}`}>
                                <h2 className="mb-md-4 mb-3 pb-md-2">Other</h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">What amenities are provided?</h3>
                                    <p>
                                        Amenities vary by property, but most properties include basic amenities such as bedding, towels, and toiletries. Some properties may also include additional amenities such as a fully equipped kitchen, laundry facilities, and Wi-Fi. Please refer to the property listing page for a complete list of amenities provided.
                                    </p>
                                </div>
                                <h2 className="mb-md-4 mb-3 pb-md-2"></h2>
                                <div className="pb-md-2">
                                    <h3 className="h5">Do you offer travel insurance?</h3>
                                    <p>
                                        Yes, we offer travel insurance for an additional fee. Our travel insurance covers trip cancellations, interruptions, and delays, as well as emergency medical expenses and lost or delayed baggage. Please contact us for more information.
                                    </p>
                                </div>
                                <div></div>
                            </div>
                        </div>
                    </div>
                </section>

	
	<section class="container mb-5 pb-lg-5">
		<div class="row align-items-sm-center">
			<div class="col-sm-5">
				<img src={activesupport} alt="Illustration"/>
			</div>
			<div class="col-md-6 offset-md-1 col-sm-7 text-sm-start text-center">
				<h2 class="mb-4 pb-md-3">
					Can’t find what you are looking for?
				</h2>
				<a class="btn btn-lg btn-primary" href="https://shortstaybuddy.com/contact">Contact us now</a>
			</div>
		</div>
	</section>
</div>


</div>
  )
}

export default HelpCenter