import React, { useEffect, useState } from 'react';
import favicon from "../../../assets/images/favicon.png";
import favicon2 from "../../../assets/images/site_logo.png";

import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import { Link, useLocation } from 'react-router-dom';

function Sidebar() {
    useEffect(() => {
        document.body.classList.add('two-sidebar');
    }, []);

    const [toogleSidebar, setToggleSidebar] = useState(false);

    const handletoggleSidebar = () => {
        setToggleSidebar(!toogleSidebar);
        if (toogleSidebar) {
            document.body.classList.remove('sidebar-main');
        } else {
            document.body.classList.add('sidebar-main');
        }
    };

    const [toggleUsers,setToggleUsers] = useState(false)
    const [toggleCommission,setToggleCommission] = useState(false)
    const [toggleReports,setToggleReports] = useState(false)
    const [toggleDashboard,setToggleDasboard] = useState(false)
    const [togglePromoCode,setTogglePromoCode] = useState(false)
    const [toggleSettings,setToggleSettings] = useState(false)
const location=useLocation()

    useEffect(()=>{
      if (location.pathname==="/partner") {
          setToggleDasboard(true)
      }
      if (location.pathname==="/partner/commision"){
          setToggleCommission(true)
      }
 

   if (location.pathname==="/partner/users"){
    setToggleUsers(true)
    
}

if (location.pathname==="/partner/reports"){
   setToggleReports(true)
   
}



if (location.pathname==="/partner/promo_codes"){

 setTogglePromoCode(true)
}

if (location.pathname==="/partner/settings"){

 setToggleSettings(true)

}











  },[])
    return (
        <>
            <div className="iq-sidebar-small bg-primary">
                <div className="sidebar-top">
                    <div className="iq-sidebar-small-logo">
                        <Link to="/partner">
                            <img src={favicon} style={{ height: "30px", width: "30px" }} className="img-fluid" alt="Logo" />
                        </Link>
                    </div>
                    <div className="sidebar-menu-icon">
                        <Link to="/partner" className="iq-waves-effect" data-toggle="tooltip" data-placement="right" title="" data-original-title="Home">
                            <Tooltip style={{ background: "#0084ff", color: "white" }} size='small' title="Home" position="top" trigger="mouseenter">
                                <i className="ri-home-2-fill"></i>
                            </Tooltip>
                        </Link>
                        <Link to="/partner/commision">
                            <Tooltip style={{ background: "#0084ff", color: "white" }} size='small' title="Commissions" position="top" trigger="mouseenter">
                                <i className="ri-percent-line"></i>
                            </Tooltip>
                        </Link>
                        <Link to="/partner/reports">
                            <Tooltip style={{ background: "#0084ff", color: "white" }} size='small' title="Reports" position="top" trigger="mouseenter">
                                <i className="ri-file-chart-2-line"></i>
                            </Tooltip>
                        </Link>
                        <Link to="/partner/users" className="iq-waves-effect">
                            <Tooltip style={{ background: "#0084ff", color: "white" }} size='small' title="Users" position="top" trigger="mouseenter">
                                <i className="ri-group-line"></i>
                            </Tooltip>
                        </Link>
                        <Link to="/partner/promo_codes" className="iq-waves-effect">
                            <Tooltip style={{ background: "#0084ff", color: "white" }} size='small' title="Promo Codes" position="top" trigger="mouseenter">
                                <i className="ri-hashtag"></i>
                            </Tooltip>
                        </Link>
                        <Link to="/partner/settings" className="iq-waves-effect" data-toggle="tooltip" data-placement="right" title="" data-original-title="Settings">
                            <Tooltip style={{ background: "#0084ff", color: "white" }} size='small' title="Settings" position="top" trigger="mouseenter">
                                <i className="ri-settings-2-fill"></i>
                            </Tooltip>
                        </Link>
                    </div>
                </div>
                <div className="sidebar-bottom">
                    <div className="sidebar-menu-icon">
                        <div className="iq-menu-bt align-self-center" onClick={handletoggleSidebar}>
                            <div className={`wrapper-menu ${toogleSidebar ? "open" : ""}`}>
                                <div className="menu-close"><i className="ri-arrow-left-line"></i></div>
                                <div className="menu-open"><i className="ri-arrow-right-line"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="iq-sidebar">
                <div className="iq-sidebar-logo d-flex justify-content-start">
                    
                </div>
                <div id="sidebar-scrollbar" data-scrollbar="true" tabIndex="-1" style={{ overflow: 'hidden', outline: 'none' }}>
                    <div className="scroll-content">
                        <nav className="iq-sidebar-menu">
                            <ul id="iq-sidebar-toggle" className="iq-menu">
                                <li className="iq-menu-title"><i className="ri-separator"></i><span></span></li>
                                <li className={`${toggleDashboard?"active":""}`}>
                                    <Link to="/partner" data-toggle="collapse" aria-expanded="false">
                                        <i className="ri-home-4-line"></i><span>Dashboard</span>
                                    </Link>
                                </li>
                                <li className={`${toggleCommission?"active":""}`}>
                                    <Link to="/partner/commision" data-toggle="collapse" aria-expanded="false">
                                        <i className="ri-percent-line"></i><span>Commission</span>
                                    </Link>
                                </li>
                                <li className={`${toggleReports?"active":""}`}><Link to="/partner/reports" className="iq-waves-effect"><i className="ri-file-chart-2-line"></i><span>Report</span></Link></li>
                                <li className={`${toggleUsers?"active":""}`}>
                                    <Link to="/partner/users" data-toggle="collapse" aria-expanded="false">
                                        <i className="ri-group-line"></i><span>Users</span>
                                    </Link>
                                </li>
                                <li className={`${togglePromoCode?"active":""}`}><Link to="/partner/promo_codes" className="iq-waves-effect"><i className="ri-hashtag"></i><span>Promo Codes</span></Link></li>
                                <li className={`${toggleSettings?"active":""}`}><Link to="/partner/settings" className="iq-waves-effect"><i className="ri-settings-2-fill"></i><span>Settings</span></Link></li>
                            </ul>
                        </nav>
                        <div className="p-3"></div>
                    </div>
                    <div className="scrollbar-track scrollbar-track-y" style={{ display: 'block' }}>
                        <div className="scrollbar-thumb scrollbar-thumb-y" style={{ height: '895.321px', transform: 'translate3d(0px, 0px, 0px)' }}></div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Sidebar;
