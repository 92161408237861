import React,{useState,useEffect} from 'react'
import { Link ,useNavigate} from 'react-router-dom'
import { useGetBlogMutation,useGetBlogCategoriesMutation} from '../features/property/propertySlice'
import Skeleton from 'react-loading-skeleton'

function Blog() {
const [getblog,{isLoading}]=useGetBlogMutation()
const [getblogcategories]=useGetBlogCategoriesMutation()
const [blogs,setblogs]=useState()
const [blogcategories,setBlogCategories]=useState()
const navigate=useNavigate()
const handleGetBlogs=async()=>{
	try{
		const response= await getblog()
		console.log(response.data)
		setblogs(response.data)
		
	}catch(error){
		console.log(error)
	}
	
}

const handleGetBlogCategories=async()=>{
	try{
        const response= await getblogcategories()
        console.log(response.data)
        setBlogCategories(response.data)
        
    }catch(error){
        console.log(error)
    }
}

useEffect(()=>{

	handleGetBlogs()
	handleGetBlogCategories()
},[])


const handleNavigate=(id,slug)=>{

	
	navigate(`/posts/detail/${id}`)
}
  return (


    <div class="container mt-5 mb-md-4 py-5">
<nav class="mb-3 pt-md-3" aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item"><Link to='/'> Home</Link></li>
				<li class="breadcrumb-item active" aria-current="page">Our Blog</li>
			</ol>
		</nav>

        <h1 class="d-flex align-items-end justify-content-between mb-4">Short Stay Buddy news</h1>


        <div >

		<div class="row gy-3 mb-4 pb-2 mt-4">
			<div class="col-md-4 order-md-1 order-2">
				<div class="position-relative">
					<input class="form-control pe-5" type="text" placeholder="Search articles by keywords..." />
					<i class="fi-search position-absolute top-50 end-0 translate-middle-y me-3"></i>
				</div>
			</div>
			<div class="col-lg-6 col-md-8 offset-lg-2 order-md-2 order-1">
				<div class="row gy-3">
					<div class="col-6 d-flex flex-sm-row flex-column align-items-sm-center">
						<label class="d-inline-block me-sm-2 mb-sm-0 mb-2 text-nowrap" for="categories">
							<i class="fi-align-left mt-n1 me-2 align-middle opacity-70"></i>Category:
						</label>
						<select class="form-select" id="categories" >
							<option value="">All</option>
							{
								blogcategories&& blogcategories.map((category,index)=>{
									return(
										<option key={index} value={category.name}>{category.name}</option>
									)
								})
							}
															
														
													</select>
					</div>
					<div class="col-6 d-flex flex-sm-row flex-column align-items-sm-center">
						<label class="d-inline-block me-sm-2 mb-sm-0 mb-2 text-nowrap" for="sortby">
							<i class="fi-arrows-sort mt-n1 me-2 align-middle opacity-70"></i>Sort by:
						</label>
						<select class="form-select" id="sortby" >
							<option value="date-desc" selected="">
								Newest
							</option>
							<option value="date-asc">
								Oldest
							</option>
							<option value="featured-desc">
								Featured
							</option>
						</select>
					</div>
				</div>
			</div>
		</div>
	

	<div class="row row-cols-md-2 row-cols-1 gy-md-5 gy-4 mb-lg-5 mb-4">
				
				{
					isLoading? 
					<>
					<article class="col pb-2 pb-md-1"><Skeleton style={{width:"100%",height:"250px"}}/>
					<Skeleton style={{width:"50%",height:"20px"}}/>
					<Skeleton style={{width:"50%",height:"20px"}}/>
					<div className='d-flex '>
					<Skeleton circle={true} style={{width:"80px",height:"80px"}}/>
					<div className='mt-2 mx-2'>
					<Skeleton style={{width:"100px",height:"10px"}}/>
					<Skeleton style={{width:"100px",height:"10px"}}/>
					</div>
					

					

					</div>
					
					
					</article>

					<article class="col pb-2 pb-md-1"><Skeleton style={{width:"100%",height:"250px"}}/>
					<Skeleton style={{width:"50%",height:"20px"}}/>
					<Skeleton style={{width:"50%",height:"20px"}}/>
					<div className='d-flex '>
					<Skeleton circle={true} style={{width:"80px",height:"80px"}}/>
					<div className='mt-2 mx-2'>
					<Skeleton style={{width:"100px",height:"10px"}}/>
					<Skeleton style={{width:"100px",height:"10px"}}/>
					</div>
					

					

					</div>
					
					
					</article>

					
					</>:
					
						blogs&&blogs.results.length > 0 ?
						
						 blogs.results.map((blog,index)=>{
							const date = new Date(blog.created_at);

                            return(
								<article key={index} class="col pb-2 pb-md-1">
				<a class="d-block position-relative mb-3" >
										<img class="d-block rounded-3" style={{height:'300px', objectFit:'cover', width:'100%'}} src={blog.image} alt="Article image"/>
				</a>
				<a class="fs-sm text-uppercase text-decoration-none">
					{blog.category.name}
				</a>
				<h3 class="h5 mb-2 pt-1">
					<a style={{cursor:"pointer"}} onClick={()=>{handleNavigate(blog.id,blog.slug)}} class="nav-link" >
						
						{ blog.title.length >= 70? blog.title.slice(0,70)+"..." : blog.title}

					</a>
				</h3>
				<p class="mb-3">



					<div dangerouslySetInnerHTML={{ __html: blog.body.length >= 200? blog.body.slice(0,200)+"..." : blog.body }} />

					
				</p>
				<a class="d-flex align-items-center text-decoration-none" href="#">
					<img class="rounded-circle" src={blog.author.profile} width="48" alt={blog.author.first_name +" "+blog.author.last_name} />
					<div class="ps-2">
						<h6 class="fs-base text-nav lh-base mb-1">{blog.author.first_name} {blog.author.last_name}</h6>
						<div class="d-flex text-body fs-sm">
							<span class="me-2 pe-1">
								<i class="fi-calendar-alt opacity-70 mt-n1 me-1 align-middle"></i>
								
								{date.toDateString()}
							</span>
							<span>
								<i class="fi-chat-circle opacity-70 mt-n1 me-1 align-middle"></i>
								
								No comments
							</span>
						</div>
					</div>
				</a>
			</article>

							)	
						
						})

						:
						<div className='py-5  col-12'>
						<div class="col-12"><div class="card shadow-sm card-hover border-0 h-100"><div class="card-body position-relative pb-3"><h4 class="mb-1 fs-xs fw-normal text-uppercase text-primary">Nothing Here!</h4><h3 class="h6 mb-2 fs-base">There are currently no blog posts!</h3></div></div></div>

						</div>
					

		
				}
			
				
		
			</div>

	
	<nav class="pt-4 pb-2 border-top" aria-label="Blog pagination">
		<div>
	</div>

		
	</nav>


</div>

    </div>
  )
}

export default Blog