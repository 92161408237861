import { createApi,fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { setCredentials,logOut } from "../../features/auth/authSlice";



const baseQuery=fetchBaseQuery({
    baseUrl:" https://testing.shortstaybuddy.com/",
    credentials: 'include',
    
    prepareHeaders:(headers,{getState})=>{
        const token =getState().auth.access
        

        if (token) {
            headers.set('authorization',`JWT ${token}`,)
            headers.set( 'Content-Type','multipart/form-data')
        }
        
        return headers
    }
})


const BaseQueryWithReauth=async (args,api,extraOptions)=>{
    let result = await baseQuery(args,api,extraOptions)
    
if (result?.error?.status === 401){
       
            console.log('sending refresh token')
       
        const refreshTokenResponse =await  fetch('https://testing.shortstaybuddy.com/auth/refresh-token/', {
            
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
              
             
            },
            body:JSON.stringify({
                'refresh':localStorage.getItem('refresh')

            })
               
            
          });
          const refreshToken = await refreshTokenResponse.json();
 
        if (refreshToken?.access){

            const user=api.getState().auth.user_id;
            const is_number_verified=api.getState().auth.is_number_verified;
            const refresh=api.getState().auth.refresh;


            //store user
            api.dispatch(setCredentials({access:refreshToken.access,refresh:refresh,user_id:user,is_number_verified:is_number_verified}));
            //retry original request with new access token
            result=await baseQuery(args,api,extraOptions)
        } 
        else{
            api.dispatch(logOut())
        }

    }

   return result
}


export const apiSlice1=createApi({
    baseQuery: BaseQueryWithReauth,
    endpoints:builder=>({})
})