import React, { useEffect, useState } from 'react';
import Sidebar from '../../../components/partners/basic/sidebar';
import { FilePond, registerPlugin } from 'react-filepond';
import Navbar from '../../../components/partners/basic/navbar';
import { Link } from 'react-router-dom';

import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import { useGetprofileMutation } from '../../../features/auth/authApiSlice';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateSize,
  FilePondPluginFileEncode
);

function Settings() {
  const [getprofile, { isLoading }] = useGetprofileMutation();
  const [profiledata, setProfileData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    confirmPassword: '',
  });
  const [files, setFiles] = useState([]);


  // Fetch profile data
  const handleGetProfile = async () => {
    try {
      const response = await getprofile().unwrap();
      console.log(response)
      setProfileData({
        firstName: response.first_name || '',
        lastName: response.last_name || '',
        email: response.email || '',
        phoneNumber: response.phone_number || '',
       
      });

      
		if (response.profile !==null){
			
			const url=response.profile.replace("https://api.shortstaybuddy.com","")


			fetch(url)
			.then(response => {
			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }
			  return response.blob();
			})
			.then(blob => {
			  // Create a File object from the Blob
			  const file = new File([blob], 'profile-picture.png', { type: 'image/png' });
	  
			  // Add the file object to the FilePond files array
			  setFiles([file]);
			})
			.catch(error => {
			  console.error('Error fetching image:', error);
			});
		}

    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    handleGetProfile();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };

  return (
    <>
      <Navbar />
      <Sidebar />
      <div className="content-page px-2" id="content-page">
        <div className="container">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb bg-primary mb-0">
              <li className="breadcrumb-item" style={{ marginTop: "5px" }}>
                <Link to='/partner' className="text-white">
                  <i className="ri-home-4-line mr-1 float-left"></i>
                  <span>Dasboard</span>
                </Link>
              </li>
              <li className="breadcrumb-item"></li>
              <li style={{ marginTop: "5px" }}>
                <Link to='/partner/settings' className="text-white" style={{ marginTop: "5px" }}>
                  Settings
                </Link>
              </li>
            </ol>
          </nav>

          <div className="iq-card">
            <div className="iq-card-header d-flex justify-content-between">
              <div className="iq-header-title">
                <h4 className="card-title">Settings</h4>
              </div>
            </div>
            <div className="iq-card-body">
              <form className='row'>
                <div className="col-md-6">
                  <label htmlFor="firstName">First Name</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="firstName"
                    name="firstName"
                    value={profiledata.firstName}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="lastName">Last Name</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="lastName"
                    name="lastName"
                    value={profiledata.lastName}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="form-control form-control-sm"
                    id="email"
                    name="email"
                    value={profiledata.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={profiledata.phoneNumber}
                    onChange={handleChange}
                  />
                </div>

                

                <div className="col-md-6 mt-2">
                  <FilePond
                    allowMultiple={false}
                    files={files}
                    disabled
                    name="avatar_url"
                    stylePanelLayout="compact"
                    styleButtonRemoveItemPosition="left"
                    styleButtonProcessItemPosition="right"
                    styleLoadIndicatorPosition="right"
                    styleProgressIndicatorPosition="right"
                    styleButtonRemoveItemAlign={false}
                    acceptedFileTypes={['image/png', 'image/jpeg', 'image/jpg']}
                  />
                </div>

                <div className='d-flex justify-content-start mt-4 border-t'>
                  <button className='btn btn-success me-2'>Save</button>
                  <button className='btn btn-primary'>Update</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Settings;
