import React,{useEffect, useState} from 'react'
import Sidebar from '../../../components/partners/basic/sidebar'

import Navbar from '../../../components/partners/basic/navbar'
import { Link } from 'react-router-dom'
import image from "../../../assets/images/page-img/01.jpg"
import { usePartnerDashboardMutation,useUserPromocodeMutation } from '../../../features/partners/partnersSlice'
import Skeleton from 'react-loading-skeleton'
import { selectCurrentUser } from '../../../features/auth/authSlice'
import { useSelector } from 'react-redux'
import CountUp from 'react-countup';


function Dashboard() {
  const [getPartnerDashboard,{isLoading}] = usePartnerDashboardMutation()
  const [getPromocode,{isLoading:isPromocodeLoading}] = useUserPromocodeMutation()
  const [dashboardData,setDashboardData]=useState()
  const [promoCodes,setPromoCodes]=useState()
  const user_id=useSelector(selectCurrentUser)
  useEffect(()=>{
 console.log(user_id)
  },[])


  const handleGetPromocodes=async()=>{
    try{

      const response= await getPromocode({"user_id":user_id}).unwrap()
      console.log(response)
      setPromoCodes(response.results)

    }catch(e){
      console.log(e)
    }
  }

  const handleGetDasboardData=async()=>{
    try{
      const response= await getPartnerDashboard().unwrap()
      console.log(response)
      setDashboardData(response)
      

    }
    catch(error){
      console.error(error)
    }
  }

  useEffect(()=>{
    handleGetDasboardData()
    handleGetPromocodes()
  },[])

  // Function to get days interval between two dates
  const getDaysInterval = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeDiff = endDate - startDate;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convert from milliseconds to days
  };

  // Function to format dates
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };


  return (
    <>
     <Navbar/>
        <Sidebar />
       
        <div className="content-page px-2  " id="content-page">
       



                           <div class="container">
                           <nav aria-label="breadcrumb ">
                              <ol class="breadcrumb bg-primary mb-0">
                                 <li class="breadcrumb-item"><Link  to="/partner" href="#" class="text-white"><i class="ri-home-4-line mr-1 float-left"></i>Dashboard</Link></li>
                              </ol>
                           </nav>
                     <div class="row mt-3">
                       <div class="col-md-6 col-lg-4">
                       {
                              isLoading?
                              <Skeleton style={{height:"100px"}}/>
                              :
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-1 text-success">
                             
                           
                              <div class="iq-card-body">
                                <div class=""> 
                                   <h5>Today's Referrals</h5>
                                   <h4>
                                   <CountUp duration={3} end={parseInt(dashboardData&&dashboardData.todays_refereal)}/>
                                   </h4>
                                 </div>
                             </div>

                            
                          </div>
                        }
                       </div>
                       <div class="col-md-6 col-lg-4">
                       {
                              isLoading?
                              <Skeleton style={{height:"100px"}}/>
                              :
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-2 text-success">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>This Week's Referrals</h5>
                                   <h4>
                                   <CountUp duration={3} end={parseInt(dashboardData&&dashboardData.week_refereal)}/>
                                  </h4>
                                 </div>
                             </div>
                          </div>
                          }
                       </div>
                       <div class="col-md-6 col-lg-4">
                       {
                              isLoading?
                              <Skeleton style={{height:"100px"}}/>
                              :
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-3 text-success">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>Total Referrals (This Month)</h5>

                                   <h4>
                                   <CountUp duration={3} end={parseInt(dashboardData&&dashboardData.month_refereal)}/>
                                  </h4>
                                 </div>
                             </div>
                          </div>
                       }
                       </div>
                     
                     
                      
                       
                       
                       
                      </div>

                      <div class="row mt-3">
                       <div class="col-md-6 col-lg-4">
                       {
                              isLoading?
                              <Skeleton style={{height:"100px"}}/>
                              :
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-1 text-warning">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>This weeks's Commision</h5>
                                   

                                   <h4>
                                   GH₵
                                   <CountUp duration={3} end={parseInt( dashboardData&&dashboardData.weeks_commission)}/>
                                  </h4>
                                 </div>
                             </div>
                          </div>
                       }
                       </div>
                       <div class="col-md-6 col-lg-4">
                       {
                              isLoading?
                              <Skeleton style={{height:"100px"}}/>
                              :
                          <div class="iq-card iq-card-block iq-card-stretch iq-card-height iq-border-box iq-border-box-2 text-warning">
                             <div class="iq-card-body">
                                <div class=""> 
                                   <h5>This month's Commision</h5>


                                   <h4>
                                   GH₵
                                   <CountUp duration={3} end={parseInt(dashboardData&&dashboardData.month_commission)}/>
                                  </h4>
                                 </div>
                             </div>
                          </div>
                       }
                       </div>
                     
                     
                     
                      
                       
                       
                       
                      </div>

                      <div className='row mt-3'>
                     
                      
                      <div className="iq-card"  style={{overflowX:"scroll"}} >
  <div className="iq-card-header d-flex justify-content-between">
    <div className="iq-header-title">
      <h4 className="card-title">Current Promo Detail</h4>
    </div>
    <div className="iq-card-header-toolbar d-flex align-items-center">
      <div className="dropdown">
        <span
          className="dropdown-toggle"
          id="dropdownMenuButton1"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="ri-more-2-fill"></i>
        </span>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="dropdownMenuButton"
        >
          <a className="dropdown-item" href="#">
            <i className="ri-eye-fill mr-2"></i>View
          </a>
          <a className="dropdown-item" href="#">
            <i className="ri-delete-bin-6-fill mr-2"></i>Delete
          </a>
          <a className="dropdown-item" href="#">
            <i className="ri-pencil-fill mr-2"></i>Edit
          </a>
          <a className="dropdown-item" href="#">
            <i className="ri-printer-fill mr-2"></i>Print
          </a>
          <a className="dropdown-item" href="#">
            <i className="ri-file-download-fill mr-2"></i>Download
          </a>
        </div>
      </div>
    </div>
  </div>
  <div className="iq-card-body" style={{ minWidth: "1000px", overflowX: "scroll" }}>
    <div className="row" >
    {
      isPromocodeLoading?
      <>
      <Skeleton  className='col-12' style={{width: "100%",height:"30px"}}/>
      <Skeleton  className='col-12' style={{width: "100%",height:"30px"}}/>
      <Skeleton  className='col-12' style={{width: "100%",height:"30px"}}/>
      <Skeleton  className='col-12' style={{width: "100%",height:"30px"}}/>

      </>
      :
      promoCodes&&promoCodes.map((code)=>{
        return(
<>
<div key={code.id} className="col-4 text-start mt-3">
        <h4 className=''>Code </h4>
        <h5 className="mb-0 mt-1 text-muted">{code.name}</h5>
      </div>
      <div className="col-4 text-center mt-3">
        <h6>Percentage </h6>
        <h5 className="mt-1">{code.percentage}%</h5>
      </div>
      <div className="col-4 text-center mt-3">
        <h6>Days Left </h6>
        <h5 className="mt-1">{}
        
        <CountUp duration={3} end={parseInt(getDaysInterval(code.start_date,code.end_date))} />
        </h5>
        <p>{formatDate(code.start_date)}-{formatDate(code.end_date)}</p>
      </div>
</>
        )
      })
    }
    
     
    </div>
  </div>
</div>

                      </div>
                  </div>
        </div>

    
    </>
  )
}

export default Dashboard