import { useState ,useRef} from 'react'
import signuplogo from '../asserts/img/signin-modal/adduser.svg'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { useRegisterMutation,useLoginMutation,useLogiWithGoogleMutation} from '../features/auth/authApiSlice';
import { setCredentials } from '../features/auth/authSlice';
import { useGoogleLogin } from '@react-oauth/google';
import ReCAPTCHA from 'react-google-recaptcha';
import "../asserts/css/theme.min.css"


function SignupPage() {
     const navigate = useNavigate();
     const  [seepassword,setseepassword]=useState(false)
     const  [seeconfirmpassword,setconfirmseepassword]=useState(false)
	 const[register,{isLoading:isloadingregister}]=useRegisterMutation()
	 const[login,{isLoading:isloadinglogin}]=useLoginMutation()
   const [loginwithGoogle,{isLoading:isLoadingGoogle}]=useLogiWithGoogleMutation()
   const [recapValue,setRecapValue]=useState()

	 const dispatch = useDispatch()
	 const emaildanger=useRef(null)
	 const phonedanger=useRef(null)








  // Sort the country codes array alphabetically by the label

 
	 

	 const passworddanger=useRef(null)

	 const formref=useRef(null)

	const [confirmpassword,setconfirmpassword]=useState('')
	const [phonenumber,setPhonenumber]=useState('')
	const[zip,setZip]=useState("")
	 const [formData, setFormData] = useState({
    role:'user',
		first_name: "",
		last_name: ""
	 	,email: "",
		password:"",
		phone_number:"",
		

	});




  
  const goBack = () => {
    navigate(-1);
   
  };





  const handleSignup= async(e)=>{
e.preventDefault()
	
    const isValid = formref.current.checkValidity(); // Check if all form elements are valid

	
	
	

    if (isValid) {

		formData.phone_number=`${zip}${phonenumber}`
		console.log(confirmpassword)
		if(formData.password===confirmpassword){
			console.log(
				formData
			)
	
	
		
		
		try{
       
const signupData= await register(formData).unwrap()
console.log(signupData)
setFormData({
  role:"",
  first_name: "",
  last_name: ""
   ,email: "",
  password:"",
  phone_number:"",
  

})


if (signupData.status==="success"){

	const signinData= await login({"email":formData.email,'password':formData.password}).unwrap()
	console.log(signinData)
	if (signinData.is_email_verified===false){
	dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_email_verified:signinData.is_email_verified}))
	  navigate("/verify")
	}
	else{
		dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_email_verified:signinData.is_email_verified}))
	  navigate("/")
	}
  


}



		
		}
		catch(e){
			if (e.data.email){
				emaildanger.current.innerText="user with this email address already exists."
				
			}
			
		}
		 

		}
		else{
			passworddanger.current.innerText="The password confirmation does not match"
			

		}
	
			
     
    } else {
		formref.current.classList.add("was-validated")
	    }

       
    


  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleLoginSuccess = async (response) => {
    try {
  
      console.log(response.credential)
      const signinData = await loginwithGoogle({access_token: response.credential})
      console.log(signinData)
  
      if (signinData.is_email_verified===false){
        dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_email_verified:signinData.is_email_verified}))
  
        navigate("/verify")
      }
      else{
        dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh,user_id:signinData.user_id,is_email_verified:signinData.is_email_verified}))
        navigate("")
      }
    
    formData.email=""
    formData.password=""
    
    // Store the JWT or session token from the backend
    } catch (error) {
      console.error('There was a problem with your fetch operation:', error);
    }
  };


  const loginUsingGoogle = useGoogleLogin({
    onSuccess: async(codeResponse) => {

      const signinData = await loginwithGoogle({ access_token: codeResponse.access_token }).unwrap();
      dispatch(setCredentials({access:signinData.access,refresh:signinData.refresh}))
      navigate("/dashboard/personal-info")
  
  
  
  
      
    },
    onError: (error) => console.log('Login Failed:', error)
  });


  return (
    <div className=" fade show fade py-5" style={{diaplay:"block",backgroundColor:"#f0f0f0"}} id="signup-modal" tabindex="-1" aria-hidden="true">
    <div className="modal-dialog modal-lg modal-dialog-centered p-2 my-0 mx-auto" style={{maxWidth: "950px"}}>
      <div className="modal-content">
        <div className="modal-body px-0 py-2 py-sm-0">
        <buton  onClick={goBack} style={{cursor:"pointer"}} class=" position-absolute top-0 end-0 nav-link fs-sm py-1 px-2 mt-3 me-3" href="#" onclick="window.history.go(-1); return false;">
				<i class="fi-arrow-long-left fs-base me-2"></i>
				Go back
			</buton>          <div className="row mx-0 align-items-center">
            <div className="col-md-6 border-end-md p-4 p-sm-5">
              <h2 className="h3 mb-4 mb-sm-5">
              Join Short Stay Buddy.<br/>Get premium benefits:
              </h2>
              <ul className="list-unstyled mb-4 mb-sm-5">
                <li className="d-flex mb-2"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Add and promote your property</span></li>
                <li className="d-flex mb-2"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Easily manage your wishlist</span></li>
                <li className="d-flex mb-0"><i className="fi-check-circle text-primary mt-1 me-2"></i><span>Leave reviews</span></li>
              </ul><img className="d-block mx-auto" src={signuplogo} width="344" alt="Illustartion"/>
              <div className="mt-sm-4 pt-md-3">Already have an account? <Link to='/login' data-bs-toggle="modal" data-bs-dismiss="modal">Sign in</Link></div>
            </div>
            <div className="col-md-6 px-4 pt-2 pb-4 px-sm-5 pb-sm-5 pt-md-5">
              
              <form className="needs-validation" ref={formref}  novalidate>

              <button  type='button' onClick={loginUsingGoogle} class="btn btn-outline-info rounded-pill  w-100 mb-3 mt-3 " href="#"> 
              <svg aria-hidden="true" class="native svg-icon iconGoogle mb-1" width="18" height="18" viewBox="0 0 18 18"><path fill="#4285F4" d="M16.51 8H8.98v3h4.3c-.18 1-.74 1.48-1.6 2.04v2.01h2.6a7.8 7.8 0 0 0 2.38-5.88c0-.57-.05-.66-.15-1.18"></path><path fill="#34A853" d="M8.98 17c2.16 0 3.97-.72 5.3-1.94l-2.6-2a4.8 4.8 0 0 1-7.18-2.54H1.83v2.07A8 8 0 0 0 8.98 17"></path><path fill="#FBBC05" d="M4.5 10.52a4.8 4.8 0 0 1 0-3.04V5.41H1.83a8 8 0 0 0 0 7.18z"></path><path fill="#EA4335" d="M8.98 4.18c1.17 0 2.23.4 3.06 1.2l2.3-2.3A8 8 0 0 0 1.83 5.4L4.5 7.49a4.8 4.8 0 0 1 4.48-3.3"></path></svg>
               <span className="mx-1">Sign up with Google</span> </button>

                <div className="mb-4">
                  <label className="form-label  "   for="signup-name">First name</label>
                  <input       name="first_name"   value={formData.first_name} onChange={handleChange} className="form-control" type="text" placeholder="Enter your first name" required/>
                </div>

                <div className="mb-4">
                  <label className="form-label" for="signup-name">Last name</label>
                  <input value={formData.last_name} name='last_name' onChange={handleChange} className="form-control" type="text" id="signup-name" placeholder="Enter your other name(s)" required/>
                </div>
                <div className="mb-4">
                  <label className="form-label" for="signup-email">Email address</label>
                  <input  value={formData.email} name='email' onChange={handleChange} className="form-control" type="email" id="signup-email" placeholder="Enter your email" required/>

				  <span class="text-danger" ref={emaildanger}></span>
                </div>
                <div class="mb-4">
							<label class="form-label" for="signup-phone">Phone Number</label>
							<div class="input-group form-group">
								<div class="col-5 text-muted">
								
									<select onChange={(e) => setZip(e.target.value)}  class="form-select" required  >
									<option  selected value="+233"> +233</option>


									</select>
								</div>

								<div class="col-7">
									<input name="phone_number"   onChange={(e) => setPhonenumber(e.target.value)} className="form-control" type="number"  placeholder="e.g. 240000000" minlength="9" min="0"  required/>
								</div>
							</div>
							<span class="text-danger" ref={phonedanger}></span>
													</div>

                <div className="mb-4">
                  <label className="form-label" for="signup-password">Password </label><br/>
				  <span className='fs-xs text-muted ' >min. 8 char & at least a special character</span>
                  <div className="password-toggle">
                    <input name="password"
        value={formData.password}
        onChange={handleChange} className="form-control " pattern=".*[\W_].*" type={seepassword?"text":"password"} id="signup-password" minlength="8" required/>
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input className="password-toggle-check" type="checkbox"/><span className="password-toggle-indicator" onClick={()=>{setseepassword(!seepassword)}}></span>
                    </label>
                  </div>
				  <span class="text-danger" ref={passworddanger}></span>
                </div>
                <div className="mb-4">
                  <label className="form-label" for="signup-password-confirm">Confirm password</label>
                  <div className="password-toggle">
                    <input className="form-control"   onFocus={()=>{passworddanger.current.innerText=""}}  onBlur={()=>{passworddanger.current.innerText=""}} onChange={(e) => setconfirmpassword(e.target.value)}   pattern=".*[\W_].*" type={seeconfirmpassword?"text":"password"} id="signup-password-confirm" minlength="8" required/>
                    <label className="password-toggle-btn" aria-label="Show/hide password">
                      <input name="confirmpassword" className="password-toggle-check" type="checkbox"/><span onClick={()=>{setconfirmseepassword(!seeconfirmpassword)}} className="password-toggle-indicator"></span>
                    </label>
                  </div>
                </div>
              
                <div className="form-check mb-4">
                  <input className="form-check-input" type="checkbox" id="agree-to-terms" required/>
                  <label className="form-check-label" for="agree-to-terms">By joining, I agree to the <Link  to='/terms-of-service'>Terms of use</Link> and <Link  to='/privacy-policy'>Privacy policy</Link></label>
                </div>
             


				{
					isloadingregister?<button type="button" class="btn btn-primary mb-2  btn-lg rounded-pill w-100 mb-4   ">
  <span class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
   registering ...
  
</button>: <button className="btn mb-2  btn-primary btn-lg rounded-pill w-100 " onClick={handleSignup} >Sign up</button>

}



              </form>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default SignupPage