import React ,{useState}from 'react'



function FAQComponent({question,answer}) {
    const [show,setShow]=useState(false)

  return (
    <div class="accordion-item">
    <h2 class="accordion-header" id="pq-heading-1">
      <button onClick={()=>{setShow(!show)}} class="accordion-button fw-normal collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#pq-collapse-1" aria-expanded="false" aria-controls="pq-collapse-1">{question}</button>
    </h2>
    <div class={`accordion-collapse ${ show? 'show' :""} collapse`} aria-labelledby="pq-heading-1" data-bs-parent="#accordionPQ" id="pq-collapse-1" >
      <div class="accordion-body fs-sm">{answer}</div>
    </div>
  </div>
  )
}

export default FAQComponent