import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../../asserts/css/pageloading.css';  // Correcting the path if necessary

function PageLoading() {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation(); // Hook from React Router to detect route changes

  useEffect(() => {
    // Function to handle the activation and fade-out of the loading screen
    const activateLoading = () => {
      setIsActive(true); // Activate loading

      // Set a timeout to deactivate loading after 2 seconds
      setTimeout(() => {
        setIsActive(false);
      }, 1000);
    };

    // Call activateLoading when the component mounts
    activateLoading();

    // Additionally, call activateLoading whenever the location changes
    return () => {}; // Clean-up function does nothing here
  }, [location]); // Dependency array includes location to react to route changes

  // Do not render the component if not active
  if (!isActive) return null;

  return (
    <div className={`page-loading ${isActive ? 'active' : ''}`}>
      <div className="page-loading-inner">
        <div className="page-spinner"></div>
        <span>Loading...</span>
      </div>
    </div>
  );
}

export default PageLoading;
